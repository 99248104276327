.signup-success-message{
    color: green!important;
    text-align: left;
    margin-top: 20px;
    word-wrap: break-word; 
    font-weight: 500;
}

.signup-error-message{
    color: #CD242B!important;
    text-align: center;
    margin-top: 20px;
}

input[type="password"]::-ms-reveal, 
input[type="password"]::-ms-clear {
    display: none;
}