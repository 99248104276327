.filter-section {
  display: flex;
  justify-content: space-between;
  margin: 25px;
  flex-wrap: wrap;
  row-gap: 15px;
}
.filter-buttons {
  display: flex;
  justify-content: space-between;
  .button {
    background: #887952;
    color: white;
    border: none;
    padding: 11px;
    border-radius: 6px;
    font-size: medium;
    width: 120px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
  }
  .submit-button {
    margin-left: 5px;
  }
}
.file-upload-tracking-table {
  margin: 0px 25px !important;
  height: 60vh;
  overflow-y: auto;
  overflow-x: auto;
  border: 1px solid #DDDADA;
  border-radius: 6px !important;
  tr th {
    background-color: #eeee !important;
  }
  tr td {
    background-color: #fff !important;
  }
  tr td,
  th {
    padding: 8px;
  }
  tr td:nth-child(1) {
    left: 0;
    max-width: 5px;
    min-width: 5px;
  }
  tr td:nth-child(2) {
    left: 0;
    max-width: 120px;
    min-width: 120px;
    text-align: left;
  }
  tr td:nth-child(3) {
    left: 0;
    max-width: 100px;
    min-width: 100px;
    text-align: left;
  }
  tr td:nth-child(4) {
    left: 0;
    max-width: 220px;
    min-width: 220px;
    text-align: left;
  }
  tr td:nth-child(5) {
    left: 0;
    max-width: 130px;
    min-width: 130px;
    text-align: left;
  }
  tr td:nth-child(6) {
    left: 0;
    max-width: 200px;
    min-width: 200px;
    text-align: left;
  }
  tr td:nth-child(7) {
    left: 0;
    max-width: 180px;
    min-width: 180px;
  }
  tr td:nth-child(8) {
    left: 0;
    max-width: 150px;
    min-width: 150px;
  }
  tr td:nth-child(9) {
    left: 0;
    max-width: 100px;
    min-width: 100px;
  }
  tr td:nth-child(10) {
    left: 0;
    max-width: 100px;
    min-width: 100px;
  }
}
