/* Full-screen overlay */
.owner-popup-overlay {
    position: fixed;
    top: 300px;
    left: -200px;
    background: rgba(0, 0, 0, 0.6); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000; 
    width: 60%;
    border-radius: 8px;
    .owner-popup {
        background: #fff;
        border-radius: 8px;
        padding: 20px;
        text-align: center;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
        z-index: 1001; 
        width: fit-content; 
        height: 150px;
        .owner-popup-message {
          font-size: 0.85rem;
          color: #555;
          margin-bottom: 20px;
          text-align: left;
        }
        .owner-popup-buttons {
          display: flex;
          justify-content: space-around;
          margin-top: 15px;
          gap: 2%;
          .no-owner-button {
            background: white;
            color: black;
            padding: 8px 15px;
            border: 1px solid black;
            border-radius: 4px;
            cursor: pointer;
          }
        
          .yes-owner-button {
            background: #785607;
            color: white;
            padding: 8px 15px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
          .no-owner-button, .yes-owner-button {
              width: 48%;
              height: 2.5rem;
          }
        }
      }
  }
  
    
  
  