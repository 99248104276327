/* Full-screen overlay */
.view-guides-video-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1080;

  .view-guides-video-popup {
    background: #fff;
    border-radius: 8px;
    width: 750px;
    z-index: 1001;
    overflow: scroll;
    .view-guides-video-head {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 700;
      line-height: 33.6px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      border-bottom: 1px solid #d8d8d8;
      padding: 20px;
    }

    .view-guides-video-section {
      text-align: center;
      padding: 20px;
      padding-bottom: 20px;
    }
    .view-guides-video-bottom {
      display: flex;
      flex-direction: row-reverse;
      padding: 20px 20px;
      border-top: 1px solid #d8d8d8;
      .cancel-button {
        background: white;
        color: black;
        padding: 8px 15px;
        border: 1px solid rgb(215, 215, 215);
        border-radius: 6px;
        cursor: pointer;
        font-weight: 600;
        font-size: medium;
      }
    }
  }
}
