.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 600px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}
.modal-header {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.181);
  padding: 0px 0px 0px 10px;
  justify-content: space-between;
  font-weight: 500;
  .close-button {
    border: none;
    background: none;
    cursor: pointer;
  }
}

.modal-body {
  padding: 10px;
  .template-section {
    border: 1px dashed rgba(0, 0, 0, 0.181);
    margin: 15px;
    padding: 5px;
    border-radius: 10px;
    p {
      font-weight: 500;
    }
    .download-link {
      display: block;
      margin: 15px 0;
      color: #007bff;
      text-decoration: none;
    }

    .download-link:hover {
      text-decoration: underline;
    }
  }
  .upload-section {
    .upload-button {
      background-color: #8b6f3a;
      color: white;
      padding: 10px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .upload-button:hover {
      background-color: #887952;
    }
  }
}
