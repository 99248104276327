.view-funeral-homes-special-instruction-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;

  .add-more-special-instruction-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    height: 40px;
    width: 200px;
    background: none;
    border: 1px solid #785607;
    border-radius: 6px;
    padding: 10px;
    color: #785607;
    font-size: 16px;
    font-family: Roboto;
    cursor: pointer;
  }

  .special-instruction-list-div {
    margin-top: 50px;
    max-height: 40vh;
    overflow: auto;

    .no-special-instruction-found {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
    }

    .eachSpecialInstruction-div {
      border-radius: 8px;
      background-color: #f0f0f0;
      box-sizing: border-box;

      .normal-special-instruction-mode {
        .special-instruction-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid grey;
          h3 {
            margin: 0;
            padding: 20px;
            padding-top: 2px;
            padding-bottom: 0px;
          }

          .edit-special-instruction-icon-div {
            background-color: white !important;
            height: 25px;
            width: 25px;
            align-items: center;
            border-radius: 4px;
            z-index: 20;
            margin: 10px;
            cursor: pointer;

            .edit-special-instruction-icon {
              margin-left: 5px;
              margin-top: 5px;
            }
          }
        }

        .special-instruction-text {
          padding: 20px;
          padding-top: 0px;
          line-height: 2;
          font-family: Roboto;
        }

        .notes-text-date {
          display: flex;
          justify-content: end;
          margin-top: 0px;
          padding-bottom: 12px;
          padding-right: 12px;
          font-family: Roboto;
          font-weight: 400;
          font-size: 14px;
          line-height: 16.41px;
          letter-spacing: 0px;
          text-align: right;
          color: #727272;
        }
      }

      .edit-special-instruction-mode {
        border: 1px solid black;
        border-radius: 6px;
        position: relative;
        height: 250px;
        margin-bottom: 10px;

        h3 {
          margin: 0;
          padding: 20px;
          padding-bottom: 5px !important;
          border-bottom: 1px solid grey;
        }

        .edit-special-instruction-textarea {
          border: none !important;

          &:focus {
            outline: none;
          }

          padding: 20px;
          line-height: 2;
          resize: none;
          width: 95%;
          height: 120px;
        }

        .edit-buttons-special-instruction {
          position: absolute;
          right: 10px;
          bottom: 0px;
          display: flex;
          gap: 1rem;
          margin-bottom: 20px;

          .edit-special-instruction-save,
          .edit-special-instruction-delete {
            border-radius: 6px;
            height: 40px;
            font-size: 16px;
            cursor: pointer;
          }

          .edit-special-instruction-save {
            background-color: #887952;
            border: none;
            color: white;
            width: 120px;
          }
          .edit-notes-cancel {
            background-color: white !important;
            border: 1px solid black !important;
            color: black;
            width: 80px;
            cursor: pointer;
            font-family: Roboto;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0px;
            border-radius: 6px;
          }
          .edit-special-instruction-delete {
            background-color: white;
            border: 1px solid red;
            color: red;
            width: 80px;
          }
        }
      }
    }

    .eachSpecialInstruction-div-add-special-instruction-mode {
      border: 1px solid black;
      border-radius: 6px;
      position: relative;
      height: 250px;
      margin-bottom: 10px;

      p {
        padding: 10px;
        padding-left: 0px;
        margin: 0px 10px;
        border-bottom: 1px solid grey;
      }

      .new-special-instruction-textarea {
        border-top: 1px solid grey !important;
        border: none !important;

        &:focus {
          outline: none;
        }

        padding: 10px;
        line-height: 2;
        resize: none;
        width: 95%;
        height: 120px;
      }

      .edit-buttons-special-instruction {
        position: absolute;
        right: 10px;
        bottom: 0px;
        display: flex;
        gap: 1rem;
        margin-bottom: 20px;

        .edit-special-instruction-save,
        .edit-special-instruction-delete {
          border-radius: 6px;
          height: 40px;
          font-size: 16px;
          cursor: pointer;
        }

        .edit-special-instruction-save {
          background-color: #887952;
          border: none;
          color: white;
          width: 120px;
        }

        .edit-notes-cancel {
          background-color: white !important;
          border: 1px solid black !important;
          color: black;
          width: 80px;
          cursor: pointer;
          font-family: Roboto;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0px;
          border-radius: 6px;
        }

        .edit-special-instruction-delete {
          background-color: white;
          border: 1px solid red;
          color: red;
          width: 80px;
        }
      }
    }

    .eachSpecialInstruction-div.editing {
      background: none !important;
    }
  }
}
