.confirm-roles-popup-overlay {
    position: fixed;
    top: 300px;
    left: 550px;
    width: 25%;
    background: rgba(0, 0, 0, 0.6); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1080; 
    border-radius: 8px;
    .roles-confirm-popup {
        background: #fff;
        border-radius: 8px;
        padding: 20px;
        text-align: center;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
        z-index: 1001; 
        width: 100%; 
        height: 180px;
        .roles-confirm-title {
          margin-top: 0px;
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 25px;
          text-align: left;
        }
        .roles-confirm-message {
          font-size: 0.85rem;
          color: #555;
          margin-bottom: 20px;
          text-align: left;
        }
        .roles-confirm-buttons {
          display: flex;
          justify-content: space-around;
          margin-top: 15px;
          gap: 2%;
          .roles-confirm-cancel-button {
            background: white;
            color: black;
            padding: 8px 15px;
            border: 1px solid black;
            border-radius: 4px;
            cursor: pointer;
          }
        
          .roles-confirm-button {
            background: #887952;
            color: white;
            padding: 8px 15px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
          .roles-confirm-cancel-button, .roles-confirm-button {
              width: 48%;
              height: 2.5rem;
          }
        }
      }
  }