.view-funeral-homes-right-container {
  max-width: 100%;
  height: 100%;
  .notes-view {
    .notes-header {
      border: 1px solid #dfdddd;
      display: flex;
      font-weight: 500;
      font-size: 20px;
      align-items: center;
      padding: 16px 10px;
      gap: 8px;
    }

    .back-arrow {
      cursor: pointer;
      font-size: 25px;
      font-weight: bold;
      .back-arrow-text {
        font-family: Roboto;
        font-weight: 500;
        font-size: 20px;
        line-height: 23.44px;
        letter-spacing: 0%;
      }
    }

    .notes-date-header {
      display: flex;
      align-items: center;
      justify-content: center;
      color: gray;
      font-size: 14px;
      font-weight: bold;
      margin: 16px 0;

      .line {
        flex: 1;
        height: 1px;
        background: lightgray;
        border: none;
        margin: 0 10px;
      }
    }

    .notes-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px 12px;
      max-height: 50vh;
      overflow: auto;
      .note-box {
        background: #f4f6f8;
        padding: 12px;
        border-radius: 8px;
        font-size: 14px;
        color: #333;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        .notes-text {
          word-break: break-all;
        }
        .notes-text-date {
          display: flex;
          justify-content: end;
          margin-top: 10px;
          font-family: Roboto;
          font-weight: 400;
          font-size: 14px;
          line-height: 16.41px;
          letter-spacing: 0px;
          text-align: right;
          color: #727272;
        }

        strong {
          display: block;
          font-size: 16px;
          margin-bottom: 5px;
        }

        p {
          margin: 0;
          line-height: 1.5;
        }

        .note-time {
          display: block;
          font-size: 12px;
          color: gray;
          margin-top: 8px;
          text-align: right;
        }
      }
    }
  }
  .quick-links-header-section {
    display: flex;
    padding: 10px 10px;
    justify-content: space-between;
    border: 1px solid #dfdddd;
    align-items: center;
    .quick-links-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-items: center;
      .quick-links-icon-expanded {
        height: 50px;
      }
    }
    .quick-links-header {
      h2 {
        margin: 0;
        font-family: Roboto;
        font-weight: 500;
        font-size: 20px;
        line-height: 23.44px;
        letter-spacing: 0%;
      }
      margin-left: 6px;
    }
    .quick-links-closeIcon {
      cursor: pointer;
    }
  }
  .quick-links-list-section {
    .quick-link-div {
      display: flex;
      border: 1px solid #dfdddd;
      margin: 10px;
      padding: 16px;
      border-radius: 6px;
      gap: 1rem;
      cursor: pointer;
      &:hover {
        border: 2px solid #785607;
      }
      &.quickLinksCollapsed {
        width: 25px;
      }
    }
  }
}
