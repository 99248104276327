.table-container {
  border-collapse: collapse;
  width: 100%;
  margin-right: 3px;
}
.table-header-row {
  font-size: 14px !important;
  background-color: #eeee !important;
  top: 0;
  position: sticky;
  th {
    padding: 8px;
    font-weight: 500;
    img {
      margin-left: 15px;
    }
  }
  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.table-body {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px !important;
  td {
    text-align: center;
  }
}
.no-records {
  text-align: center;
  padding: 20px;
}

.sortable-column {
  cursor: pointer;
  user-select: none;

  &:hover {
    color: #007bff;
  }
}
.table-component_truncated-text__kRNWG {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
