.loader-section {
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  bottom: 0;
  height: 100vh;
  z-index: 2000;
  position: fixed;
  background-color: rgba( #000, 0.3);
  &.loader-block {
    width: auto;
    height: auto;
    position: absolute;
    .loader {
      height: auto;
      > div {
        background-color: '#fff';
      }
    }
  }
  .loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      width: 13px;
      height: 13px;
      margin: 32px 3px;
      background:  #fff;
      border-radius: 50%;
      animation: bouncing-loader 0.6s infinite alternate;
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}