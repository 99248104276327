.contracts-templates-table{
    tr th{
        background-color:#EEEE!important;
    }
    tr td{
        background-color: #fff!important;
    }
    tr td, th{
        padding: 8px;
    }
    tr td:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0; 
        max-width: 68px;
        min-width: 68px;
    }
    tr th:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        max-width: 68px;
        min-width: 68px;
    }
    tr td:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 68px; 
        max-width: 114px;
        min-width: 114px;
    }
    tr th:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 68px;
        max-width: 114px;
        min-width: 114px;
        background-color:#EEEE;
    }
    tr th:nth-child(3), tr td:nth-child(3) {
        max-width: 182px;
        min-width: 182px;
    }
    tr th:nth-child(4), tr td:nth-child(4) {
        max-width: 146px;
        min-width: 146px;
    }
    tr th:nth-child(5), tr td:nth-child(5) {
        max-width: 181px;
        min-width: 181px;
    }
    tr th:nth-child(6), tr td:nth-child(6) {
        max-width: 197px;
        min-width: 197px;
    }
    tr th:nth-child(7), tr td:nth-child(7) {
        max-width: 101px;
        min-width: 101px;
    }
}

.view-existing-file {
    display: flex;
    align-items: center;
    a{
        margin-left: 5px;
        text-decoration: none;
    }
}

.view-download-template{
    display: flex;
    font-size: 14px;
    font-weight: bold;
    color: #555;
    a{
        margin-left: 5px;
        text-decoration: none;
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px; 
    display: inline-block;
    vertical-align: middle;
  }