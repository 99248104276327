.add-or-edit-form-group {
    line-height: 33px;
    margin-top: 10px;
    .custom-form-group-row {
      display: flex;
      width: 98%;
      gap: 1rem;
      .custom-form-group-col {
        width: 50%;
        .custom-input-form-control, .autocomplete-div{
          width: 100% !important;
          box-sizing: border-box;
        }
        .custom-form-label {
          display: block;
          font-weight: bold;
          font-size: 1rem;
        }
        .form-control {
          border: none !important;
          background-color: #F7F7F7;
          font-size: medium;
          height: 2.85rem;
          border-radius: 6px;
          padding: 0.5rem;
        }
        .autocomplete-div {
          background-color: #F7F7F7;
          border: none !important;
          height: 2.85rem;
          display: flex;
          align-items: center;
          padding: 0px !important;
          box-sizing: border-box;
          overflow: hidden;
          border-radius: 6px;
          box-sizing: border-box;
          .autocomplete-text-field {
            height: 100%; 
            width: 100%;
            margin: 0;
            display: flex;
            align-items: center;
            > div {
              height: 100%;
            }
            input {
              height: 100%;
              width: 100%;
              padding: 0px !important;
              padding-left: 10px !important;
              margin: 0px !important;
            }
          }
        }
        input.form-control:focus, textarea.form-input-text-area.form-control:focus {
          outline: none;
        }
        .form-input-text-area.form-control {
          height: 6.5rem;
        }
      }
    }

    .custom-form-div {
      border: 1px solid rgba(0, 0, 0, 0.181);
      border-radius: 3px;
      padding: 0.7rem 0.5rem;
      box-sizing: content-box;
    }
  }
.error-message {
    color: #dc3545;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 9px;
}

.add-or-edit-form-group .custom-form-group-row .custom-form-group-col .MuiOutlinedInput-root {
  padding: 0px;
  margin-left: -5px;
}