.offcanvas {
  position: fixed !important;
  right: 0;
  top: 0;
  width: 650px;
  z-index: 6000;
  background-color: white;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 3px;
  .offcanvas-header {
      margin: 0px 0px;
      box-sizing: border-box;
      height: 84px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.181);
      .header-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          h2 {
              margin: 0;
              padding: 0 16px;
              font-size: 1.5rem;
          }
      }
  }
  .offcanvas-body {
    margin-top: 15px;
    padding: 20px;
  
    .view-form {
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      .form-group {
        display: flex;
        flex-direction: column;
        gap: 5px;
  
        label {
          font-size: 14px;
          font-weight: bold;
          color: #555;
        }
  
        .data-value {
          margin-top: 10px;
          font-size: 16px;
          color: #333;
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 10px;
        }
      }
    }
  }
}

.scroll-text {
  max-height: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  word-break: break-word;
  padding: 5px;
}