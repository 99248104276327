@import '../../../assets/styles/variables.scss';
.main-layout {
  .sidebar {
    background-color: $army-brown;
    color: white;
    transition: width 0.3s ease, padding-right 0.3s ease;
    overflow: hidden;
    min-width: 270px;
    &.collapsed {
      width: 73px; 
      padding-right: 0px;
      min-width: 73px;
      .list-unstyled{
        min-width: 53px;
      }
      .nav {
        padding: 28px 10px;
      }
      .nav-link {
        padding: 17px 16px!important;
      }
      .collapsed-item {
        width: 53px!important;
      }
      .sidebar-header {
        display: none!important;
      }
      .sidebar-hamburger-icon{
        display: flex;
        justify-content: flex-end!important;
        align-content: flex-end!important;
      }
      .hamburgerIconOne{
        display: none!important;
      }
      .hamburgerIconTwo{
        display: block!important;
      }
      .side-bar-icon-text{
        min-width: 53px;
        .side-bar-text{
          margin-left: 0px;
          display: none;
        }
      }

      .side-bar-icon-text-arrow{
        min-width: 30px!important;
        .side-bar-icon-text{
          .side-bar-text{
            margin-left: 0px!important;
            display: none;
          }
        }
        .side-bar-arrow{
          cursor: pointer;
          display: none;
        }
      }
    }
    .side-menu{
      overflow: scroll !important;
      height: calc(100vh - 250px);
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .side-menu::-webkit-scrollbar {
      display: none;
    }
    .list-unstyled{
      overflow: scroll !important;
      // height: calc(100vh - 250px);
      min-width: 244px;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .list-unstyled::-webkit-scrollbar {
      display: none;
    }
    .sidebar-hamburger-icon{
      display: flex;
      justify-content: space-between!important;
      align-content: center!important;
      width: 100%;
      .sidebar-hamburger-icon-logo{
        display: flex;
        justify-content: space-around!important;
        width: 100%;
        padding: 20px 0px;
        padding-bottom: 0px;
      }
      img.hamburgerIconOne {
        cursor: pointer;
        height: 25px;
        width: 25px;
      }
      img.hamburgerIconTwo{
        margin-top: 16px;
        margin-left: 20px;
        margin-right: 20px;
        display: none;
      }
    }

    .sidebar-header {
      display: flex;
      justify-content: center;  
      margin-top: -20px;
      img.logo-image {
        max-width: 95%;
        height: auto;
      }
    }
    .side-bar-border-bottom{
      border-bottom: 1px solid #ADADAD;
    }
    .nav {
      padding:17px 15px;
      margin-top: 0px;
      .sub-menu {
        .collapse-hidden {
          display: none !important;
        }        
      }
      .menu-open {
        background-color: $hover-color;
        border-radius: 8px;
        .items-menu {
          border-top: 1px solid $army-brown;
        }
      }
      .collapsed-item {
        width: 40px;
      }
      .disabledClassName{
        background-color:  #776e5e;
        cursor: not-allowed!important;
        :hover {
          background-color:  #776e5e;
          cursor: not-allowed!important;
        }
      }
      .active-item {
        background-color: white !important;
        .nav-link {
          color: #785607 !important;
        }
      } 
      >.nav-item,
      .sub-menu > .nav-item,
      .sub-menu > .items-menu > .nav-item,
      .side-bar-footer > .nav-item {
        list-style: none;
        &:hover {
          color: #ffffff;
          background-color: $hover-color;
          border-radius: 8px;
        }
  
        .nav-link {
          color: white;
          display: flex;
          padding: 12px;
          text-decoration: none;
          font-size: 14px;  
          &.nav-active {
            color: #ecf0f1;
            background-color: $hover-color;
  
            .icon img {
              filter: brightness(1.5);
            }
          }
  
          .icon {

            display: flex;
            align-items: center;
            margin-right: 10px;
            img {
              width: 24px;
              height: 24px;
            }
            span {
                margin-left: 17px ;
              }
            button {
              border: none;
              background-color: $army-brown;
              margin-left: 15px; 
            }
          }
        }
      }
      .side-bar-icon-text{
        display: flex!important;
        align-items: center;
        .side-bar-icon{
          width: 24px;
          height: 24px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .side-bar-text{
          margin-left: 17px;
        }
      }

      .side-bar-icon-text-arrow{
        display: flex!important;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .side-bar-icon-text{
          .side-bar-icon{
            width: 24px;
            height: 24px;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .side-bar-text{
            margin-left: 17px;
          }
        }
        .side-bar-arrow{
          cursor: pointer;
        }
      }
    }
  }
}
  