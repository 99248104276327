@import '../../../assets/styles/variables.scss';
.funeral-homes-table {
  height: 65vh;
  tr th {
    background-color: #eeee !important;
  }
  tr td {
    background-color: #fff !important;
  }
  tr td,
  th {
    padding: 8px;
  }
  tr td:nth-child(1) {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    max-width: 53px;
    min-width: 53px;
  }
  tr th:nth-child(1) {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    max-width: 53px;
    min-width: 53px;
  }
  tr td:nth-child(2) {
    position: -webkit-sticky;
    position: sticky;
    left: 68px;
    max-width: 164px;
    min-width: 164px;
  }
  tr th:nth-child(2) {
    position: -webkit-sticky;
    position: sticky;
    left: 68px;
    max-width: 164px;
    min-width: 164px;
    background-color: #eeee;
  }
  tr th:nth-child(3),
  tr td:nth-child(3) {
    max-width: 246px;
    min-width: 246px;
  }
  tr th:nth-child(4),
  tr td:nth-child(4) {
    max-width: 246px;
    min-width: 246px;
  }
  tr th:nth-child(5),
  tr td:nth-child(5) {
    max-width: 211px;
    min-width: 211px;
  }
  tr th:nth-child(6),
  tr td:nth-child(6) {
    max-width: 180px;
    min-width: 180px;
  }
  tr th:nth-child(7),
  tr td:nth-child(7) {
    max-width: 231px;
    min-width: 231px;
  }
  tr th:nth-child(8),
  tr td:nth-child(8) {
    max-width: 190px;
    min-width: 190px;
  }
  tr th:nth-child(9),
  tr td:nth-child(9) {
    max-width: 210px;
    min-width: 210px;
  }
  tr th:nth-child(10),
  tr th:nth-child(10) {
    max-width: 200px;
    min-width: 200px;
  }
  tr th:nth-child(11),
  tr td:nth-child(11) {
    max-width: 260px;
    min-width: 260px;
  }
  tr th:nth-child(12),
  tr td:nth-child(12) {
    max-width: 150px;
    min-width: 10px;
  }
  tr th:nth-child(13),
  tr td:nth-child(13) {
    max-width: 220px;
    min-width: 220px;
  }
  tr th:nth-child(14),
  tr td:nth-child(14) {
    max-width: 140px;
    min-width: 140px;
  }
  tr th:nth-child(15),
  tr td:nth-child(15) {
    max-width: 140px;
    min-width: 140px;
  }
  tr th:nth-child(16),
  tr td:nth-child(16) {
    max-width: 180px;
    min-width: 180px;
  }
}

.funeral-homes-section {
  overflow: auto;
}
