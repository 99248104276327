@import '../../../assets//styles/variables.scss';
.row-search-bar-filter {
  margin: 10px 30px;
  .search-bar-filter {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-content: center;
    height: 66px;
    .search-bar-input-text {
      width: 80%;
      .MuiFormLabel-root {
        font-family: Roboto;
        font-size: 21px !important;
        font-weight: 400;
        line-height: 22.4px;
        letter-spacing: -0.006em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #212121 !important;
      }
      .MuiFormControl-root {
        height: 40px !important;
        width: 100%;
        background-color: #f7f7f7;
        border: none !important;
        .MuiInputBase-root {
          border: 1px solid #f7f7f7 !important;
          height: 40px !important;
          border: none !important;
        }
        .MuiInputBase-input {
          height: 40px !important;
          border: none !important;
        }
      }
      .search-icon-divider {
        margin-right: 10px;
        background-color: #dddada;
      }

      .search-icon {
        cursor: pointer;
      }
    }
    .search-bar-div {
      display: inline-flex;
      justify-content: flex-end;
      align-content: center;
      flex-wrap: wrap;
      height: 42px;
      // margin-left: 10px;
      gap: 10px;
      width: 100%;
      .input-group {
        display: flex;
        width: 55%;
        height: 40px;
        background-color: #f7f7f7;
        border-radius: 6px;
        .form-control {
          width: 80%;
          border: none !important;
          margin-right: 80px;
          background-color: #f7f7f7;
          font-size: medium;
        }
        input.form-control:focus {
          outline: none;
        }
        .input-group-text {
          margin-top: 9px;
          margin-right: 10px;
        }
      }
      .search-bar-side-div {
        display: flex;
        align-items: center;
        gap: 10px;
        .search-icon:hover {
          margin-left: 5px;
        }
      }
      .btn {
        width: 130px;
        height: 42px;
        background-color: $army-brown;
        border-radius: 6px;
        border: none;
        color: white;
        font-size: medium;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
