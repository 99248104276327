.breadcrumb-component {
  height: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.181);
  padding-left: 22px;
  display: flex;
  align-items: center;
  .MuiBreadcrumbs-ol,
  .MuiSvgIcon-root,
  .MuiTypography-root {
    font-size: 24px !important;
    line-height: 33.6px !important;
    font-weight: 700 !important;
    color: #212121;
    .MuiBreadcrumbs-separator {
      margin: 0;
    }
    .MuiBreadcrumbs-li {
      max-width: 300px;

      .MuiTypography-root {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.breadcrumb-link {
  text-decoration: none;
  color: inherit;
}
