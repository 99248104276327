.reset-password{
    background-color: #FFF9EA;
    height: 100vh; 
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center!important;
    .reset-password-container{
        position: relative;
        background-color: #fff!important;
        height: 493px!important;
        width: 419px!important;
        padding: 40px!important;
        top: 0!important;
        bottom: 0!important;
        left: 0!important;
        right: 0!important;
        .timer-error-msg-container{
            .reset-password-error-message{
                color: #CD242B!important;
                margin-bottom: 20px;
                text-align: center;
            }
            .reset-password-success-message{
                color: green!important;
                margin-bottom: 20px;
                text-align: center;
                font-weight: 500;
            }
        }
        .reset-password-field-error-message{
            margin-top: 10px;
            text-align: left!important;
            max-width: 330px;
        }
        .reset-password-error-message{
            color: #CD242B!important;
            margin-top: 30px;
            text-align: center;
        }

        .reset-password-heading{
            text-align: center!important;
        }
        .reset-password-heading-icon{
            text-align: center!important;
        }
        .reset-password-form{
            text-align: center!important;
            width: 100%!important;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 22px;
            justify-content: center!important;
            .confirm-password-field{
                width: 315px!important;
            }
            .password-toggle-icon-styles{
                position: absolute;
                margin-top: 15px;
                background-color: #F7F7F7!important;
                height: 32px;
                padding-right: 5px;
                // margin-left: 1px;
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
                padding-top: 10px;
                padding-bottom: 0px;
            }
            // .password-toggle-icon-styles {
            //     margin-top: -35px;
            //     cursor: pointer;
            //     font-size: 1.2rem;
            //     color: #888;
            //     &:hover {
            //       color: #333;
            //     }
            // }
            .reset-password-footer-text{
                p{
                    margin-top: 0px;
                }
                a{
                    line-height: 0;
                }
            }
        }
    }
}