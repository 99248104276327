.additional-ids-table {
  overflow: auto;
  border: 1px solid #dddada;
  border-radius: 6px;

  tr th {
    background-color: #eeee !important;
  }

  tr td {
    background-color: #fff !important;
  }

  tr td,
  th {
    padding: 8px;
  }
  .error-message {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
  }
  tr td:nth-child(1) {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    max-width: 50px !important;
    min-width: 50px !important;
    z-index: 1000;
  }

  tr th:nth-child(1) {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    max-width: 50px !important;
    min-width: 50px !important;
    z-index: 1000;
  }

  tr td:nth-child(2) {
    position: -webkit-sticky;
    position: sticky;
    left: 80px;
    max-width: 264px;
    min-width: 264px;
    z-index: 1000;
  }

  tr th:nth-child(2) {
    position: -webkit-sticky;
    position: sticky;
    left: 80px;
    max-width: 264px;
    min-width: 264px;
    z-index: 1000;
    background-color: #eeee;
  }

  tr th:nth-child(3),
  tr td:nth-child(3) {
    max-width: 246px;
    min-width: 246px;
  }

  tr th:nth-child(4),
  tr td:nth-child(4) {
    max-width: 246px;
    min-width: 246px;
  }

  tr th:nth-child(5),
  tr td:nth-child(5) {
    max-width: 211px;
    min-width: 211px;
  }

  tr th:nth-child(6),
  tr td:nth-child(6) {
    max-width: 180px;
    min-width: 180px;
  }

  tr th:nth-child(7),
  tr td:nth-child(7) {
    max-width: 231px;
    min-width: 231px;
  }

  tr th:nth-child(8),
  tr td:nth-child(8) {
    max-width: 190px;
    min-width: 190px;
  }

  tr th:nth-child(9),
  tr td:nth-child(9) {
    max-width: 210px;
    min-width: 210px;
  }

  .additional-ids-table-container {
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;

    .additional-ids-table-box-active {
      tr td:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        max-width: 50px !important;
        min-width: 50px !important;
      }

      tr th:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        max-width: 50px !important;
        min-width: 50px !important;
      }

      tr td:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 80px;
        max-width: 300px !important;
        min-width: 300px !important;
        padding-right: 20px;
      }

      tr th:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 80px;
        max-width: 300px !important;
        min-width: 300px !important;
        background-color: #eeee;
        padding-right: 20px;
      }

      tr th:nth-child(3),
      tr td:nth-child(3) {
        max-width: 300px !important;
        min-width: 300px !important;
        padding-right: 20px;
      }

      tr th:nth-child(4),
      tr td:nth-child(4) {
        max-width: 300px !important;
        min-width: 300px !important;
        padding-right: 20px;
      }

      tr th:nth-child(5),
      tr td:nth-child(5) {
        max-width: 411px;
        min-width: 411px;

        .custom-form-label {
          display: none;
        }
      }

      tr th:nth-child(6),
      tr td:nth-child(6) {
        max-width: 300px !important;
        min-width: 300px !important;
      }

      tr th:nth-child(7),
      tr td:nth-child(7) {
        .custom-form-label {
          display: none;
        }

        max-width: 411px;
        min-width: 411px;
      }

      tr th:nth-child(8),
      tr td:nth-child(8) {
        max-width: 300px !important;
        min-width: 300px !important;
      }

      tr th:nth-child(9),
      tr td:nth-child(9) {
        max-width: 300px !important;
        min-width: 300px !important;
      }
    }

    .additional-ids-table-box {
      border-collapse: collapse;
      width: 100%;
      font-size: 14px !important;

      td {
        white-space: nowrap;
      }

      table,
      td {
        border-right: solid 1px #dfdddd;
        border-left: solid 1px #dfdddd;
      }

      table,
      th {
        border: 1px solid #ffff;
      }

      thead {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      .additional-ids-table-no-record {
        height: 30px !important;
        text-align: center;
        line-height: 100px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.01em;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      .additional-ids-table-column-component {
        width: 99%;
        margin: auto;

        .form-control {
          height: 35px;
          width: 97%;
        }

        .add-or-edit-form-group {
          margin-top: 0px;
        }

        .phone-number-form-group {
          margin-top: 0px;

          .custom-form-label {
            display: none !important;
          }
        }

        .custom-form-group-col {
          margin-left: 20px;
        }

        .custom-form-group-col-select {
          width: 25%;
        }

        .custom-country-code-dropdown__control {
          height: 35px;
          padding: 8px;
          width: 100%;
        }
      }
    }

    .additional-ids-table-header-column-names {
      display: flex;
      justify-content: space-between;
      font-size: 14px !important;
      font-weight: 500;

      .additional-ids-table-header-column {
        // margin-left: 10px;
      }
    }

    .additional-ids-table-sticky-column {
      .edit-icon {
        cursor: pointer;
        padding: 5px;
        border-radius: 5px;

        &:hover {
          background-color: darken(#785607, 2%);
          transition: background-color 0.2s ease;
        }
      }

      .edit-form-button-icon {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
      }

      .edit-form-icon {
        // margin-left: 5px !important;
        cursor: pointer;
        padding: 5px;
        border-radius: 5px;
      }
    }

    .truncated-text {
      display: block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .additional-ids-table-sticky-column-hover {
      cursor: pointer;
      color: #212121;

      &:hover {
        color: black;
      }
    }
  }
}

.additional-ids-table-add-btn-container {
  display: flex;
  margin-top: 16px;

  .additional-ids-table-add-btn {
    cursor: pointer;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #785607;
  }
}
