.stopwatch-container {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
      
      .time-display {
        display: flex;
        font-size: 24px;
        color: #887952;
        ;
      }
  }