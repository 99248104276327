@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.user-card {
    top: 12px;           
    right: 20px;           
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 16px;
    width: 256px;
    position: absolute;
    z-index: 1000;
}
  
.user-avatar {
    width: 50px;
    height: 50px;
    border-radius: 16px;
    object-fit: cover;
}
  
.user-info {
    width: 146px;
    height: 48px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap :6px
}
  
.user-name {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: black;
    margin: 0;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
.user-role {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #737791;
    line-height: 20px;
    margin: 0;
}
