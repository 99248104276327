// .login {
//   height: 100vh;
//   display: flex;
//   max-width: 100vw;
//   align-items: center;
//   flex-direction: column;
//   justify-content: flex-start;

//   .app-update-button {
//     text-decoration: underline;
//     color: $update-alert-color !important;
//     margin-bottom: 0;
//     margin-left: 5px;
//     border: none !important;
//   }

//   .app-update-alert {
//     font-size: 1rem;
//   }

//   .app-update-version-alert {
//     z-index: 1900 !important;
//     margin-top: 60px;
//   }

//   .content {
//     width: 100%;
//     border: 1px solid $brand-default;
//     margin: 0 auto;
//     max-width: 440px;
//     border-radius: 2px;
//     background-color: $white;

//     .heading {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       padding: 30px;
//       border-bottom: 1px solid $brand-default;
//       height: auto;
//       margin: 0;

//       .alert {
//         margin-top: 15px;
//         margin-bottom: 0;
//       }

//       .cypresslawn-logo {
//         width: 100%;
//         max-width: 300px;
//         object-fit: cover;
//         margin-bottom: 20px;
//       }

//       .subject {
//         color: $theme-tertiary-text;
//         margin: 0;
//         font-size: 16px;
//         line-height: 16px;
//         @extend .lato-regular;
//       }
//     }

//     .form-group {
//       margin: 0;
//       height: 50px;

//       .has-error {
//         border-color: $error-msg;
//       }

//       label {
//         color: $theme-tertiary-text;
//         margin: 0;
//         padding: 0 30px;
//         font-size: 10px;
//         @extend .lato-bold;
//         @include transition(all 0.3s ease-in-out);
//       }

//       input {
//         color: $theme-primary-text;
//         border: 0;
//         height: 30px;
//         z-index: 1;
//         padding: 0 30px 8px;
//         outline: none;
//         position: relative;
//         font-size: 16px;
//         border-bottom: 1px solid $brand-default;
//         background-color: transparent;
//         @extend .lato-regular;
//         @include border-radius(0);
//         @include transition(all 0.3s ease-in-out);
//       }
//     }

//     .error-msg {
//       top: 50%;
//       right: 30px;
//       color: $error-msg;
//       position: absolute;
//       font-size: 12px;
//       @include translate(0, -50%);
//     }

//     .button {
//       width: 100%;
//       margin: 36px auto 30px;
//       display: block;
//       max-width: 377px;
//     }

//     .user-role {
//       border: 0;
//       width: 90%;
//       margin-left: 20px;
//       font-size: 16px;
//     }
//   }
// }

.login-success-message{
  color: green!important;
  text-align: left;
  margin-top: 20px;
  text-wrap:"break-word";
}

.login-error-message{
    color: #CD242B!important;
    text-align: center;
    margin-top: 20px;
}

.passwordFieldOutlinedInputBox{
  .errorMessage{
    color: red!important;


}

.passwordFieldOutlinedInput,.emailFieldOutlinedInput{
    background-color: #F7F7F7;
    height: 40px;
    margin-top: 15px;
    width: 344px;
    border: 0px solid #F7F7F7!important;

    input::placeholder {
      color: #405261!important;
      padding-left: -5px;
      font-family: "Ubuntu", serif;
      font-weight: 300;
      font-style: normal;
    }
    input:-webkit-autofill {
      background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 40px #F7F7F7 inset !important; // Matches input height
    box-shadow: 0 0 0 40px #F7F7F7 inset !important; 
    -webkit-text-fill-color: #405261 !important;
    font-family: "Ubuntu", serif;
    font-weight: 300;
    border-radius: 4px;
    height: 2px;
    }

    // .MuiInputBase-input{
    //   padding-left: 8px!important;
    // }
  }

}
