.phone-number-form-group {
  line-height: 33px;
  margin-top: 10px;
  .custom-form-group-row {
    display: flex;
    width: 98%;
    .custom-form-group-col-select {
      width: 20%;
      .custom-country-code-dropdown {
        height: 100%;
      }
      .custom-country-code-dropdown__control {
        width: 88%;
        background-color: #f7f7f7 !important;
        border: none !important;
        height: 2.85rem;
        box-sizing: content-box;
        padding-left: 5px;
      }
      .custom-country-code-dropdown__indicator-separator {
        display: none;
      }
      .custom-country-code-dropdown__single-value {
        margin: 0px;
      }
    }

    .custom-form-group-col {
      flex: 1;
      margin-left: 0px;
      .custom-phone-input.form-control {
        width: 99%;
        height: 1.85rem;
        font-size: medium;
        border: none !important;
        background-color: #f7f7f7;
        padding: 0.5rem;
      }
      .custom-phone-input.form-control:focus,
      .custom-phone-input.form-select:focus {
        outline: none;
      }
    }
    .custom-country-code-dropdown__value-container {
      padding: 0px 2px !important;
    }
  }

  .custom-form-label {
    display: block;
    font-weight: bold;
    font-size: 0.9rem;
    span {
      color: rgba(61, 14, 14, 0.653);
    }
  }
}
