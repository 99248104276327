.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .checkbox-label {
    margin-right: 15px;
    font-size: 14px;
  }
  
  .checkbox-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .checkbox-input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .checkbox-box {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #888;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
  
    &.checked {
      background-color: #4caf50;
      border-color: #4caf50;
    }
  
    .checkbox-tick {
      position: absolute;
      content: "";
      top: 3px;
      left: 6px;
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  
  .checkbox-wrapper input:checked + .checkbox-box {
    background-color: #4caf50;
    border-color: #4caf50;
  }
  
  .checkbox-wrapper input:checked + .checkbox-box .checkbox-tick {
    display: block;
  }