.forgot-password {
    .h-heading{
        margin-top: 0px!important;
        margin-bottom: 0px!important;
        margin-block-start: 0px!important;
        margin-block-end: 0px!important;
    }
    .p-lable{
        margin-top: 0px!important;
        margin-bottom: 0px!important;
        margin-block-start: 0px!important;
        margin-block-end: 0px!important;
    }
    input{
        margin-top: 0px!important;
    }
    .forgot-password-email-error{
        margin-top: 20px!important;
    }
    .forgot-password-success-message{
        color: green!important;
        text-align: center;
    }
    .forgot-password-error-message{
        color: #CD242B!important;
        text-align: center;
    }
    .forgot-password-link-text{
        text-align: left;
        a{
            margin-top: -120px;
        }
    }

    .helperLinks {
        width: 100%;
        font-size: 0.9rem;
        color:  #161616;
        .helperLinksLabel{
            margin-bottom: 25px;
        }
        label {
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 25.6px;
          font-family: "Ubuntu", serif;
          font-weight: 400;
          font-style: normal;
          margin-bottom: 40px;
        }
    
        a {
          font-size: 16px;
          line-height: 25.6px;
          color: #005881;
          font-family: "Ubuntu", serif;
          font-weight: 400;
          font-style: normal;
          text-decoration: none;
          margin-top:  60px!important;
        }
    }
}