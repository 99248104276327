
.profile-page {
  max-width: 900px;
  margin-left: 50px;
  padding: 20px;

  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .profile-picture-container {
      position: relative;
      width: 95.39px;
      height: 95.98px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;

      .profile-picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }

      .camera-icon-overlay {
        position: absolute;
        bottom: 5px;
        right: 5px;
        background-color: white;
        border-radius: 50%;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .camera-icon {
        width: 20px;
        height: 20px;
      }
    }

    .profile-name {
      flex: 1;
      max-width: calc(100% - 120px);
      h2 {
        font-family: Roboto;
        font-size: 17px;
        font-weight: 500;
        line-height: 25.6px;
        color: #6f6c6c;
      }

      h1 {
        font-family: Roboto;
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        color: #212121;
        overflow-x: auto;
        white-space: nowrap;
        display: block;
      }
    }
  }

  .profile-form {
    .profile-row {
      display: flex;
      flex-wrap: nowrap;
      gap: 16px;
    }
    .form-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
      
    }
    .custom-label {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      
      color: #10100F;
    }
    .custom-input {
      font-family: Roboto;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 509.5px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #bfc0c2;
      padding: 8px;
      box-sizing: border-box;
      margin-bottom: 8px;
      outline: none;
      &:focus{
        border-color: #887952;
      }
    }
    .invalid-feedback {
      color: #dc3545;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 9px;
    }

    .save-button {
      width: 170px;
      height: 46px;
      background-color: #887952;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      font-family: Roboto;
      font-weight: bold;
      font-size: 14px;
      line-height: 22.4px;
      margin-top: 20px;
    }
    .cancel-button{
      width: 101px;
      outline: none;
      background-color: white;
      border: 1px solid #000;
      font-size: 14px;
      padding: 10px 20px;
      border-radius: 4px;
      line-height: 22.4px;
      margin-right: 10px;
      font-weight: 500;
    }
    .phone-number-row {
      display: flex;
      gap: 10px;
      .custom-country-code-dropdown, .custom-country-code-dropdown__control {
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        width: 120px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid #bfc0c2;
        box-sizing: border-box;
        margin-bottom: 8px;
      }
      .custom-country-code-dropdown {
        border: 0px !important;
      }
      .custom-phone-input {
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        width: 380px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid #bfc0c2;
        padding: 8px;
        box-sizing: border-box;
        margin-bottom: 8px;
        outline: none;
        &:focus{
          border-color: #887952;
        }
      }
      .invalid-feedback {
        color: #dc3545;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 9px;
      }
    }
  }
}
