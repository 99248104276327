@import '../../assets/styles/variables.scss';
.set-password {
    background-color: #FFF9EA;
    height: 100vh; 
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center!important;
    .set-password-container {
        position: relative;
        background-color: #fff!important;
        height: 510px!important;
        width: 419px!important;
        padding: 40px!important;
        top: 0!important;
        bottom: 0!important;
        left: 0!important;
        right: 0!important;
        .timer-error-msg-container{
            .set-password-error-message{
                color: #CD242B!important;
                margin-bottom: 20px;
                text-align: center;
            }
            .set-password-success-message{
                color: green!important;
                margin-bottom: 20px;
                text-align: center;
            }
        }
        .set-password-field-error-message{
            margin-top: 10px;
            text-align: left!important;
        }
        .set-password-error-message{
            color: #CD242B!important;
            margin-top: 30px;
            text-align: center;
        }
        .set-password-heading{
            text-align: center!important;
        }
        .set-password-heading-text {
            margin: 30px auto;
            text-align: center;
        }
        .set-password-heading-icon{
            text-align: center!important;
        }
        .set-password-form{
            text-align: center!important;
            width: 100%!important;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 22px;
            justify-content: center!important;
            .set-password-input-div {
                .set-password-input-field {
                    width: 342px;
                    height: 48px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    background-color: #F7F7F7!important;
                    .set-password-confirm-password-field{
                        background-color: #F7F7F7!important;
                        border: none;
                        width: 315px!important;
                        font-size: 16px;
                        padding: 8px;
                        &:focus {
                            outline: none;
                        }
                    }
                    .set-password-toggle-icon-styles{
                        background-color: #F7F7F7!important;
                        height: 30px;
                        margin: 9px;
                        margin-left: -1px;
                        cursor: pointer;
                        font-size: 1.2rem;
                        color: #888;
                        &:hover {
                            color: #333;
                          }
                    }
                }
                .set-password-field-error-message {
                    color: #CD242B!important;
                    text-align: center;
                }
            }
            .set-password-button {
                background-color: $buttonbackground_color;
                color: #fff;
                width: 345px;
                height: 48px;
                padding: 8px 16px;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                font-size: 16px;
                line-height: 25.6px;
                font-family: "Ubuntu", serif;
                font-weight: 300;
                font-style: normal;
            }
            .set-password-footer-text{
                p{
                    margin-top: 0px;
                }

            }
        }
    }
}