
@import '../../assets/styles/variables.scss';
.email-verification{
    background-color: #FFF9EA;
    height: 100vh; 
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center!important;
    .email-verification-container{
        position: relative;
        background-color: #fff!important;
        height: 350px!important;
        width: 419px!important;
        padding: 40px!important;
        top: 0!important;
        bottom: 0!important;
        left: 0!important;
        right: 0!important;
        .timer-error-msg-container{
            .email-verification-error-message{
                color: #CD242B!important;
                margin-bottom: 20px;
                text-align: center;
            }
            .email-verification-success-message{
                color: green!important;
                margin-bottom: 20px;
                text-align: center;
            }
        }

        .email-verification-heading{
            text-align: center!important;
        }
        .email-verification-heading-icon{
            text-align: center!important;
        }

        .email-verification-expired-container{
            .email-verification-error-message{
                color: #CD242B!important;
                margin-bottom: 20px;
                text-align: center;
            }
            .email-verification-success-message{
                color: green!important;
                margin-bottom: 20px;
                text-align: center;
            }
            .email-verification-expired-btn{
                display: flex;
                justify-content: center;
                margin-top: 60px;
                
                .btn {
                    padding: 10px 20px;
                    background-color: $army-brown;
                    border-radius: 6px;
                    border: none;
                    color: white;
                    font-size: medium;
                    cursor: pointer;
                    font-weight: bold;
                }
            }
        }
    }
}