
.funeral-home-detail{
    width: 100%;
    .funeral-home-detail-container{
        .funeral-home-detail-form{
            padding-bottom: 16px;
            .heading-name-label{
                .custom-form-label{
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #212121;
                    margin-bottom: 16px;
                }
                .form-control{
                    height: 40px;
                    border-radius: 4px;
                }
            }
                            .sub-name-label{
                                .custom-form-label{
                                    font-family: Roboto;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    text-align: left;
                                    text-underline-position: from-font;
                                    text-decoration-skip-ink: none;
                                    margin-bottom: 12px;
                                    color: #212121;                                
                                }
                                .form-control, .custom-country-code-dropdown{
                                    height: 40px;
                                    border-radius: 4px;
                                }
                                .custom-input-form-control {
                                    height: 3.4rem !important;
                                }
                            }
                            .single-line-row-form-input{
                                width: 100%;
                                .add-or-edit-form-group{
                                    border: none!important;
                                    margin-bottom: 16px;
                                    margin-top: 4px;
                                    .MuiInputBase-input::placeholder {
                                        color: #535862;
                                        font-family: Roboto;
                                        font-size: 16px;
                                        font-weight: 400;
                                        line-height: 25.6px;
                                        letter-spacing: -0.01em;
                                        text-align: left;
                                        text-underline-position: from-font;
                                        text-decoration-skip-ink: none;
                                        opacity: 0.8;
                                    }
                                    
                                    .MuiInputBase-input::-ms-input-placeholder { /* Edge 12 -18 */
                                        color: #535862;
                                        font-family: Roboto;
                                        font-size: 16px;
                                        font-weight: 400;
                                        line-height: 25.6px;
                                        letter-spacing: -0.01em;
                                        text-align: left;
                                        text-underline-position: from-font;
                                        text-decoration-skip-ink: none;
                                    }
                                    .custom-form-div{
                                        width: 100%;
                                        border: none!important;
                                        border-radius: 0px;
                                        margin-bottom: 0px;
                                        border-top: 1px solid #DFDDDD!important;
                                        padding: 12px 0px!important;
                                        margin-bottom: 0px;
                                        .MuiAutocomplete-root{
                                            height: 56px;
                                            width: 17.8rem;
                                        }
                                        .custom-form-group-row{
                                            justify-content: space-between;
                                            width: 100%;
                                            .custom-form-group-col{
                                                margin-left: 0px;
                                            }
                                        }
                                    }
                                }
                            }

                            .multi-line-row-form-input {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                margin-top: 4px;
                                .multi-line-row-form-input-single{
                                    width: 49%;
                                    .custom-form-group-col-select{
                                        width: 20%;
                                        height: 56px;
                                        .custom-country-code-dropdown{
                                            height: 56px;
                                            .custom-country-code-dropdown__control{
                                                height: 56px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }