.menu{
    background-color: rgba(0,0,0,0.4);
}

.menu-option{
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    margin-right: 30px;
}

.defaultIcon {
    margin-right: 17px !important;
    margin-left: 5px;
    cursor: pointer; 
    padding: 5px;  
    border-radius: 5px; 

    &:hover {
        background-color:darken(#785607,2%); 
        transition: background-color 0.2s ease; 
    }
}

.viewIcon{
    @extend .defaultIcon;
    width: 18px;
    margin-right: 20px !important;
    margin-left: 0px;
}

.deleteIcon{
    width: 18px;
    margin-right: 25px !important;
    margin-left: 5px;
    cursor: pointer; 
    border-radius: 5px;  
}
.option-label{
    font-size: 14px;
}