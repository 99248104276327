.roles-table-container {
  overflow-x: auto;
  overflow-y: auto;
  width: 96.5%;
  margin: 20px;
  box-sizing: border-box;
  position: relative;
  .roles-table {
    width: 98.5%;
    border-collapse: collapse;
    box-sizing: content-box;
    font-size: 14px !important;
    th,td {
      border: 1.5px solid #ddd;
      text-align: center;
      padding: 10px;
    }
    th {
      background-color: #f4f4f4;
      font-weight: bold;
    }
    td {
      input[type="checkbox"] {
        display: none; 
      }
      input[type="checkbox"].disabled-checkbox,
      input[type="checkbox"]:disabled + label {
        cursor: not-allowed; 
        background-color: #ddd !important;
      } 
      input[type="checkbox"]:disabled {
        background-color: #e0e0e0; 
        pointer-events: none; 
      }
      label {
        position: relative;
        width: 16px;
        height: 16px;
        display: inline-block;
        border: 2px solid #ddd;
        border-radius: 3px; 
        background-color: #fff;
        padding: 1px;
        font-weight: bold;
      }
      input[type="checkbox"]:checked + label::before {
        content: '\2713';
        position: absolute;
        left: 2px;
        top: 0.5px;  
        color: white; 
        font-size: 14px; 
      }
      input[type="checkbox"]:checked + label {
        background-color: #785607;
      }
    }
  }
} 
.roles-actions-container {
  position: absolute;
  right: 46px;
  .save-permission-button {
    padding: 10px 20px;
    background-color: #785607;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    width: 150px;
    height: 40px;
  }
  .save-permission-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .save-permission-button:hover:not(:disabled) {
    background-color: #785607;
  }
}
