.packages{
    margin: 42px 28px;
    .guides-billed-annually{
        display: flex;
        padding: 0px 5px;
        overflow-x: scroll;
        .guides-billed-annually-inner-box{
            width: 101px;
            min-width: 101px;
            height: 48px;
            padding: 10px 5px;
            .guides-billed-annually-inner-box-icons{
                display: flex;
                justify-content: center;
                align-content: center;
                margin-bottom: 8px;
            }
            .guides-billed-annually-inner-box-text{
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.41px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
            }
        }

    }

    .guides-funding-programs {
        display: flex;
        justify-content: space-between;
        margin-top: 28px;
        .guides-funding-programs-cards {
            border-radius: 10px;
            border: 1px solid #92A0B399;
            padding: 20px 16px;
            width: 30%;
            .guides-funding-programs-cards-header-icons-status {
                display: flex;
                justify-content: space-between;
                .cards-header-icons {
                    height: 30px;
                    width: 30px;
                }
                .cards-header-status{
                    background-color: #887952;
                    padding: 4px 14.5px;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 24px;
                    display: flex;
                    justify-content: center;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    border-radius: 67px;
                    color: #FFFFFF;
                } 
            }
            .guides-funding-programs-cards-header-text {
                margin-top: 20px;
                padding-bottom: 12px;
                border-bottom: 1px solid #CDCDCD;
                .cards-header-text-main {
                    font-family: Roboto;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 23.44px;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    
                }
                .cards-header-text-sub{
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 28px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    margin-top: 8px;                    
                } 
            }
            .guides-funding-programs-cards-prices-text {
                margin-top: 16px;
                .cards-prices-text-main {
                    font-family: Roboto;
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 30px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #000000;               
                }
                .cards-prices-text-sub{
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color:  Grey;                                  
                } 
            }
            .guides-funding-programs-cards-benefits-text {
                margin-top: 16px;
                .guides-funding-programs-cards-benefits-header-text {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 18.75px;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;                    
                    color: #000000;
                    margin-bottom: 12px;           
                }
                .guides-funding-programs-cards-benefits-list{
                    display: flex;
                    margin-left: 30px;
                    .guides-funding-programs-cards-benefits-list-img{
                        height: 16px;
                        width: 16px;
                        margin-right: 10px;
                    }
                    .guides-funding-programs-cards-benefits-list-text{
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;                        
                    }                               
                } 
            }
        }

    }
}