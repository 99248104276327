.agents-table{
    tr th{
        background-color:#EEEE!important;
    }
    tr td{
        background-color: #fff!important;
    }
    tr td, th{
        padding: 8px;
    }
    tr td:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0; 
        max-width: 53px;
        min-width: 53px;
    }
    tr th:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        max-width: 53px;
        min-width: 53px;
    }
    tr td:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 68px; 
        max-width: 184px;
        min-width: 184px;
    }
    tr th:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 68px;
        max-width: 184px;
        min-width: 184px;
        background-color:#EEEE;
    }
    tr th:nth-child(3), tr td:nth-child(3) {
        max-width: 282px;
        min-width: 282px;
    }
    tr th:nth-child(4), tr td:nth-child(4) {
        max-width: 282px;
        min-width: 282px;
    }
    tr th:nth-child(5), tr td:nth-child(5) {
        max-width: 282px;
        min-width: 282px;
    }
    tr th:nth-child(6), tr td:nth-child(6) {
        max-width: 211px;
        min-width: 211px;
    }
    tr th:nth-child(7), tr td:nth-child(7) {
        max-width: 211px;
        min-width: 211px;
    }
    tr th:nth-child(8), tr td:nth-child(8) {
        max-width: 190px;
        min-width: 190px;
    }
    tr th:nth-child(9), tr td:nth-child(9) {
        max-width: 279px;
        min-width: 279px;
    }
    tr th:nth-child(10), tr td:nth-child(10) {
        max-width: 210px;
        min-width: 131px;
    }
    tr th:nth-child(11), tr td:nth-child(11){
        max-width: 211px;
        min-width: 211px;
    }
    tr th:nth-child(12), tr td:nth-child(12) {
        max-width: 279px;
        min-width: 279px;
    }
    tr th:nth-child(13), tr td:nth-child(13) {
        max-width: 211px;
        min-width: 211px;
    }
    tr th:nth-child(14), tr td:nth-child(14) {
        max-width: 281px;
        min-width: 281px;
    }
    tr th:nth-child(15), tr td:nth-child(15) {
        max-width: 211px;
        min-width: 211px;
    }
    tr th:nth-child(16), tr td:nth-child(16) {
        max-width: 279px;
        min-width: 279px;
    }
    tr th:nth-child(17), tr td:nth-child(17) {
        max-width: 211px;
        min-width: 211px;
    }
    tr th:nth-child(18), tr td:nth-child(18)  {
        max-width: 181px;
        min-width: 181px;
    }
    tr th:nth-child(19), tr td:nth-child(19) {
        max-width: 181px;
        min-width: 181px;
    }
    tr th:nth-child(20), tr td:nth-child(20) {
        max-width: 181px;
        min-width: 181px;
    }
    tr th:nth-child(21), tr td:nth-child(21) {
        max-width: 181px;
        min-width: 181px;
    }
    tr th:nth-child(22), tr td:nth-child(22) {
        max-width: 281px;
        min-width: 281px;
    }
    tr th:nth-child(23), tr td:nth-child(23) {
        max-width: 281px;
        min-width: 281px;
    }
    tr th:nth-child(24), tr td:nth-child(24) {
        max-width: 281px;
        min-width: 281px;
    }
    tr th:nth-child(25), tr td:nth-child(25) {
        max-width: 281px;
        min-width: 281px;
    }
    tr th:nth-child(26), tr td:nth-child(26) {
        max-width: 281px;
        min-width: 281px;
    }
    tr th:nth-child(27), tr td:nth-child(27) {
        max-width: 181px;
        min-width: 181px;
    }
    tr th:nth-child(28), tr td:nth-child(28) {
        max-width: 181px;
        min-width: 181px;
    }
    tr th:nth-child(29), tr td:nth-child(29) {
        max-width: 281px;
        min-width: 281px;
    }
    tr th:nth-child(30), tr td:nth-child(30) {
        max-width: 281px;
        min-width: 281px;
    }
    tr th:nth-child(31), tr td:nth-child(31) {
        max-width: 281px;
        min-width: 281px;
    }
    tr th:nth-child(32), tr td:nth-child(32) {
        max-width: 281px;
        min-width: 281px;
    }
    tr th:nth-child(33), tr td:nth-child(33) {
        max-width: 281px;
        min-width: 281px;
    }
}

.no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    font-size: 18px;
    height: 100%;
    min-height: 200px;
  }