.view-agencies{
    position: fixed !important;
    right: 0;
    top: 0;
    width: 650px; 
    z-index: 6000;
    background-color: white;
    border: 2px solid white;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 3px;
    .offcanvas-header {
        margin: 0px 0px;
        box-sizing: border-box;
        height: 84px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.181);
        .header-div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            h2 {
                margin: 0; 
                padding: 0 16px; 
                font-size: 1.5rem; 
            }
        }
    }
    .offcanvas-body {
      .view-form-agencies {
        display: flex;
        flex-direction: column;
        .form-section{
          border: 1px solid #E1E1E1;
          padding: 15px;
          margin-top: 15px;
          padding-top: 0px;
        }
        .form-section-heading{
          font-family: Roboto;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          margin-top: 15px;
        }
        .form-group {
          display: flex;
          flex-direction: column;
          margin-top: 15px;
          label {
            font-size: 14px;
            font-weight: bold;
            color: #555;
            margin-bottom: 10px;
          }
    
          .data-value {
            font-size: 16px;
            color: #333;
            border: 1px solid #ccc;
            text-wrap: wrap;
            word-break: break-all;
            overflow: auto;
            border-radius: 4px;
            padding: 10px;
            background-color: #f9f9f9;
          }
        }
      }
    }
  }