.guides {
  .guides-search-videos {
    border-bottom: 1px solid #d2cece;
    padding: 20px 32px;
    display: flex;
    justify-content: space-between;
    .search-bar-input-text {
      width: 50%;
      .MuiFormLabel-root {
        font-family: Roboto;
        font-size: 21px !important;
        font-weight: 400;
        line-height: 22.4px;
        letter-spacing: -0.006em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #212121 !important;
      }
      .MuiFormControl-root {
        height: 40px !important;
        width: 100%;
        background-color: #f7f7f7;
        border: none !important;
        .MuiInputBase-root {
          border: 1px solid #f7f7f7 !important;
          height: 40px !important;
          border: none !important;
        }
        .MuiInputBase-input {
          height: 40px !important;
          border: none !important;
          padding: 0px;
          padding-left: 15px !important;
        }
        .MuiOutlinedInput-notchedOutline {
          border-width: 0px !important;
        }
      }
      .search-icon-divider {
        margin-right: 10px;
        background-color: #dddada;
      }

      .search-icon {
        cursor: pointer;
      }
    }
    .add-new-video-btn {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 25.6px;
      letter-spacing: -0.01em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      .btn {
        width: 148px;
        height: 42px;
        background-color: #887952;
        border-radius: 6px;
        border: none;
        color: white;
        font-size: medium;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
  .guides-video-section {
    margin-top: 10px;
    display: flex;

    .guides-video-section-right {
      min-width: 20%;
      border-right: 1px solid #d4d4d4;
      height: 78vh;
      .guides-video-section-right-container {
        padding: 0px 22px;

        .guides-video-section-right-container-heading {
          font-family: Roboto;
          font-size: 20px;
          font-weight: 700;
          line-height: 23.44px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #000000;
          margin-bottom: 20px;
        }
        .guides-video-section-right-container-list {
          box-shadow: 0px 0px 4px 0px #00000040;
          height: 72vh;
          border-radius: 9px;
          overflow: scroll;
          .Mui-disabled {
            color: #6f6c6c !important;
          }
          .guides-video-section-right-container-list-check-icons {
            margin-right: 8px;
            height: 20px;
            width: 20px;
          }
          .MuiListItem-root {
            height: 40px !important;

            .MuiListItemButton-root {
              height: 40px !important;
              font-family: Roboto !important;
              font-size: 16px;
              min-width: 200px;
              line-height: 25.6px;
              letter-spacing: -0.01em;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #000000;
              padding-top: 0px;
              padding-bottom: 0px;
            }
            .Mui-selected {
              // background-color: #E4E4E4;
              background-color: #ffff;
            }
          }
        }
      }
    }
    .guides-video-section-left {
      width: 80%;
      padding: 0px 23px;
      .guides-video-section-left-container-heading {
        display: flex;
        align-content: center;
        align-items: center;
        .guides-video-section-left-container-heading-label {
          font-family: Roboto;
          font-size: 20px;
          font-weight: 700;
          line-height: 23.44px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #000000;
        }
        .guides-video-section-left-container-heading-count {
          width: 36px;
          height: 36px;
          margin-left: 10px;
          border-radius: 42px;
          background-color: #887952;
          text-align: center;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          font-family: Roboto;
          font-size: 16.8px;
          font-weight: 500;
          color: #ffff;
        }
      }
      .guides-video-section-right-container-videos::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      .guides-video-section-right-container-videos::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
      .guides-video-section-right-container-videos {
        padding-top: 11px;
        margin-top: 11px;
        padding-left: 2px;
        display: flex;
        flex-wrap: wrap;
        overflow-x: scroll;
        height: 73vh;
        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
        .guides-video-section-right-container-videos-cards {
          box-shadow: 0px 0px 4px 0px #00000040;
          border-radius: 9px;
          padding: 10px 10px;
          min-width: 42%;
          width: 42%;
          margin-right: 25px;
          margin-bottom: 25px;
          min-height: 279px;
          max-height: 290px;
          .guides-video-section-right-container-videos-cards-video {
            min-height: 193px;
          }
          .guides-video-section-right-container-videos-cards-vide-info {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 16px;
            .guides-video-section-right-container-videos-cards-vide-info-label-duration {
              min-height: 50px;
              max-height: 70px;
              .guides-video-section-right-container-videos-cards-vide-info-label {
                font-family: Roboto;
                font-size: 15px;
                font-weight: 500;
                line-height: 23.44px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #000000;
              }
              .guides-video-section-right-container-videos-cards-vide-info-duration {
                font-family: Roboto;
                font-size: 13px;
                font-weight: 400;
                line-height: 18.75px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #6f6c6c;
              }
            }
            .guides-video-section-right-container-videos-cards-vide-info-icon {
              height: 32px;
              width: 32px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .guides-video-section {
      margin-top: 10px;
      display: flex;

      .guides-video-section-right {
        min-width: 20%;
        border-right: 1px solid #d4d4d4;
        height: 78vh;
        .guides-video-section-right-container {
          padding: 0px 22px;

          .guides-video-section-right-container-heading {
            font-family: Roboto;
            font-size: 20px;
            font-weight: 700;
            line-height: 23.44px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
            margin-bottom: 20px;
          }
          .guides-video-section-right-container-list {
            box-shadow: 0px 0px 4px 0px #00000040;
            height: 72vh;
            border-radius: 9px;
            overflow: scroll;
            .Mui-disabled {
              color: #6f6c6c !important;
            }
            .guides-video-section-right-container-list-check-icons {
              margin-right: 8px;
              height: 20px;
              width: 20px;
            }
            .MuiListItem-root {
              height: 40px !important;

              .MuiListItemButton-root {
                height: 40px !important;
                font-family: Roboto !important;
                font-size: 16px;
                min-width: 200px;
                line-height: 25.6px;
                letter-spacing: -0.01em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #000000;
                padding-top: 0px;
                padding-bottom: 0px;
              }
              .Mui-selected {
                // background-color: #E4E4E4;
                background-color: #ffff;
              }
            }
          }
        }
      }
      .guides-video-section-left {
        width: 80%;
        padding: 0px 23px;
        .guides-video-section-left-container-heading {
          display: flex;
          align-content: center;
          align-items: center;
          .guides-video-section-left-container-heading-label {
            font-family: Roboto;
            font-size: 20px;
            font-weight: 700;
            line-height: 23.44px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #000000;
          }
          .guides-video-section-left-container-heading-count {
            width: 36px;
            height: 36px;
            margin-left: 10px;
            border-radius: 42px;
            background-color: #887952;
            text-align: center;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            font-family: Roboto;
            font-size: 16.8px;
            font-weight: 500;
            color: #ffff;
          }
        }
        .guides-video-section-right-container-videos::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }
        .guides-video-section-right-container-videos::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
        .guides-video-section-right-container-videos {
          padding-top: 11px;
          margin-top: 11px;
          padding-left: 2px;
          display: flex;
          flex-wrap: wrap;
          overflow-x: scroll;
          height: 73vh;
          ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
          }

          ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
          }
          .guides-video-section-right-container-videos-cards {
            box-shadow: 0px 0px 4px 0px #00000040;
            border-radius: 9px;
            padding: 10px 10px;
            min-width: 42%;
            width: 42%;
            margin-right: 25px;
            margin-bottom: 25px;
            min-height: 279px;
            max-height: 290px;
            .guides-video-section-right-container-videos-cards-video {
              min-height: 193px;
            }
            .guides-video-section-right-container-videos-cards-vide-info {
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: 16px;
              .guides-video-section-right-container-videos-cards-vide-info-label-duration {
                min-height: 50px;
                max-height: 70px;
                .guides-video-section-right-container-videos-cards-vide-info-label {
                  font-family: Roboto;
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 23.44px;
                  text-align: left;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;
                  color: #000000;
                }
                .guides-video-section-right-container-videos-cards-vide-info-duration {
                  font-family: Roboto;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 18.75px;
                  text-align: left;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;
                  color: #6f6c6c;
                }
              }
              .guides-video-section-right-container-videos-cards-vide-info-icon {
                height: 32px;
                width: 32px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
