.generate-reports-container {
    height: 87%;
    width: 96%;
    margin: 20px 0px 20px 20px;
    border: 1px solid #E1E1E1;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .generate-reports-title-div {
        border: none !important;
        h3 {
            margin: 10px !important;
            border-bottom: 1px solid #E1E1E1 !important;
            padding-bottom: 12px;
        }
    }
    .generate-reports-form {
        position: relative; 
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 1rem;
        .generate-reports-row {
            display: flex;
            gap: 1px;
            .generate-reports-col {
                width: 1900px;
                margin: 10px;
                margin-left: 10px !important;
                &:last-child {
                    margin-left: 0px;
                }
                .generate-reports-form-group {
                    display: flex;
                    flex-direction: column;
                    input {
                        background-color: #F7F7F7;
                        border: none;
                        text-align: left;
                        padding: 10px;
                    }
                    label {
                        color: #212121;
                        font-size: 14px;
                        font-weight: bolder;
                        font-family: Roboto, Arial, sans-serif;
                        margin-bottom: 10px;
                    }
                    .generate-reports-autocomplete {
                        background-color: #F7F7F7;
                        border: none !important;
                        height: 2.5rem;
                        display: flex;
                        align-items: center;
                        padding: 0px !important;
                        box-sizing: border-box;
                        overflow: hidden;
                        border-radius: 6px;
                        div * {
                            padding: 0px !important;
                        }
                        input {
                            height: 100%;
                            width: 100%;
                            padding: 10px !important;
                        }
                        .generate-reports-autocomplete-inner-div {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .generate-reports-buttons-container {
            position: absolute;
            bottom: 20px;
            width: 100%;
            padding: 10px;
            display: flex;
            justify-content: flex-end;
            right: 1px;
            .generate-report-reset-button, .generate-report-button {
                height: 40px;
                font-size: 16px;
                text-align: center;
                font-family: Roboto, Arial, sans-serif;
                border-radius: 4px;
            }
            .generate-report-reset-button {
                background-color: white;
                border: 1px solid #E1E1E1;
                width: 73px;
            }
            .generate-report-button {
                margin-left: 9px;
                background-color: #887952;
                border: none !important;
                width: 146px;
                color: #F7F7F7;
            }
        }
    }
}
