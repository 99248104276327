.row {
    margin: 30px !important;

    .directory-table-container {
        height: 65vh;
        overflow-y: auto;
        overflow-x: auto;
        width: 100%;
        margin-right: 3px;
        border-radius: 6px !important;
        border: 1px solid #DDDADA;

        .table-container {
            border-collapse: collapse;
            width: 100%;
            font-size: 14px !important;
            td {
                white-space: nowrap;
            }
            .truncated-text {
              display: block; 
              max-width: 100%; 
              white-space: nowrap; 
              overflow: hidden;
              text-overflow: ellipsis; 
            }
            thead {
                position: sticky;
                top: 0;
                z-index: 1;
            }
        }

        .header-column-names-arrow {
            display: flex;
            justify-content: space-between;
            font-size: 14px !important;
            font-weight: 500;
            .header-first-column{
                margin-left: 10px;
            }
        }



        .sticky-column {
            .edit-icon {
                margin-left: 15px !important;
                cursor: pointer; 
                padding: 5px;  
                border-radius: 5px; 
                &:hover {
                    background-color:darken(#785607,2%); 
                    transition: background-color 0.2s ease; 
                }
            }
        }

        .sticky-column-hover {
            cursor: pointer;
            color: #785607;
            &:hover {
                color: black;
            }
        }

        .contract-signature{
            height: 20px;
            display: flex;
        }

        .download-icon{
            margin-left: 15px !important;
            cursor: pointer;
            padding: 5px;  
        }
    }
}