.view-funeral-home{
    width: 100%; 
    height: 92vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .view-funeral-home-left-right-section {
      display: flex;
      width: 100%;
      .view-funeral-home-left-section {
        flex-grow: 1;
        width: 75%;
        &.quickLinksCollapsed {
          width: 93% !important;
        }
      }
      .view-funeral-home-right-section {
        width: 25%;
        transition: width 0.1s ease-in-out;
        &.quickLinksCollapsed {
          width: 7% !important;
        }
      }
    }
    .view-funeral-home-bottom-section{
      width: 100%; 
      height: 200px;
    }
  }