@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");



  .Country{
    .profile-row {
      display: flex;
      flex-wrap: nowrap;
      gap: 16px;
    }
    .form-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
      
    }
    .custom-label {
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      font-weight: 200;
      line-height: 20px;
      color: #10100f;
    }
    .custom-input {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      width: 509.5px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #bfc0c2;
      padding: 8px;
      box-sizing: border-box;
      margin-bottom: 8px;
      outline: none;
      &:focus{
        border-color: #887952;
      }
    }
    .MuiAutocomplete-root {
      .MuiOutlinedInput-root {
        padding-left: 3px;
      }
      .MuiOutlinedInput-root:hover fieldset {
        border-color: #bfc0c2;
      }
      .MuiOutlinedInput-root.Mui-focused fieldset {
        border-color: #887952;
      }
    }
    .invalid-feedback {
      color: #dc3545;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 9px;
    }

    .save-button {
      width: 170px;
      height: 46px;
      background-color: #887952;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      font-family: "Roboto", sans-serif;
      font-weight: bold;
      font-size: 14px;
      line-height: 22.4px;
      margin-top: 10px;
    }
  }

