.regional-reps-table{
    tr th{
        background-color:#EEEE!important;
    }
    tr td{
        background-color: #fff!important;
    }
    tr td, th{
        padding: 8px;
    }
    tr td:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0; 
        max-width: 53px;
        min-width: 53px;
    }
    tr th:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        max-width: 53px;
        min-width: 53px;
    }
    tr td:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 68px; 
        max-width: 164px;
        min-width: 164px;
    }
    tr th:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 68px;
        max-width: 164px;
        min-width: 164px;
        background-color:#EEEE;
    }
    tr th:nth-child(3),tr td:nth-child(3) {
        max-width: 282px;
        min-width: 282px;
    }
    tr th:nth-child(4),tr td:nth-child(4) {
        max-width: 246px;
        min-width: 246px;
    }
    tr th:nth-child(5),tr td:nth-child(5) {
        max-width: 211px;
        min-width: 211px;
    }
    tr th:nth-child(6),tr td:nth-child(6) {
        max-width: 279px;
        min-width: 279px;
    }
}