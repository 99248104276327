.add-or-edit-form-group {
    line-height: 33px;
    margin-top: 10px;
    .custom-form-label {
      display: block;
      font-weight: bold;
      font-size: 0.9rem;
      span {
        color: rgba(61, 14, 14, 0.653);
      }
    }
    .form-control {
      width: 98%;
      border: none !important;
      background-color: #F7F7F7;
      font-size: medium;
      height: 40px;
      border-radius: 6px;
      padding: 0.5rem;
    }
    .form-input-text-area {
      font-size: 12px;
      font-family: Roboto, Arial, sans-serif;
    }
    input.form-control:focus, textarea.form-input-text-area.form-control:focus {
      outline: none;
    }
    .form-input-text-area.form-control {
      height: 6.5rem;
    }
    .custom-form-div {
      border: 1px solid rgba(0, 0, 0, 0.181);
      border-radius: 3px;
      padding: 0.7rem 0.5rem;
      box-sizing: content-box;
    }
}
.error-message {
    color: #dc3545;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 9px;
    margin-top: 1px;
    text-align: right;
  }