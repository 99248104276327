.pagination-component{
    margin: 30px!important;
    .Mui-selected{
        background-color: #887952!important;
        color: #fff!important;
    }
    .Mui-selected:hover{
        background-color: #887952!important;
        color: #fff!important;
    }
    .MuiButtonBase-root{
        color: #4B4F50;
    }
    .MuiSvgIcon-root{
        color: #4B4F50!important;
    }
    .MuiPaginationItem-root{
        height: 40px!important;
        min-width: 40px!important;
        border-radius:26px;
        line-height:2.43;
        font-size: 14px;
    }
}