.contracts-signature-table{
    tr th{
        background-color:#EEEE!important;
    }
    tr td{
        background-color: #fff!important;
    }
    tr td, th{
        padding: 8px;
    }
    tr td:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0; 
        max-width: 68px;
        min-width: 68px;
    }
    tr th:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        max-width: 68px;
        min-width: 68px;
    }
    tr td:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 78px; 
        max-width: 192px;
        min-width: 192px;
    }
    tr th:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 78px;
        max-width: 192px;
        min-width: 192px;
        background-color:#EEEE;
    }
    tr th:nth-child(3), tr td:nth-child(3) {
        max-width: 182px;
        min-width: 182px;
    }
    tr th:nth-child(4), tr td:nth-child(4) {
        max-width: 182px;
        min-width: 182px;
    }
    tr th:nth-child(5), tr td:nth-child(5) {
        max-width: 182px;
        min-width: 182px;
    }
    tr th:nth-child(6), tr td:nth-child(6) {
        max-width: 162px;
        min-width: 162px;
    }
    tr th:nth-child(7), tr td:nth-child(7) {
        max-width: 197px;
        min-width: 197px;
    }
}

.existing-file{
    display: flex;
    padding-top: 20px;
    padding-bottom: 10px;
    .existing-file-label{
        font-weight: 600;
        font-size: 15px;
    }
    .data-value{
        margin-left: 10px;
        font-size: 15px;
    }
}

.scrollable-text {
    max-height: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    word-break: break-word;
    padding: 5px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
  }