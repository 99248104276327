/* Full-screen overlay */
.upload-guides-video-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1080;

  .upload-guides-video-popup {
    background: #fff;
    border-radius: 8px;
    min-width: 800px;
    z-index: 1001;
    overflow: scroll;
    .upload-guides-video-head {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid #d8d8d8;
      .upload-guides-video-head-text {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.01em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding: 20px;
        color: #212121;
      }
      .upload-guides-video-close-btn {
        padding: 20px;
        img {
          cursor: pointer;
        }
      }
    }
    .upload-guides-video-section {
      padding: 20px;
      .upload-guides-video-row-section {
        width: 100%;
        .upload-guides-video-form-section {
          width: 100%;
          .upload-guides-video-form-dropdown-section {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .upload-guides-video-form-dropdown {
              width: 48%;
            }
          }
          .upload-guides-video-form-video-section {
            display: flex;
            display: flex;
            justify-content: space-between;
            margin-top: 51px;
            .upload-guides-video-form-video-right {
              width: 59%;
              height: 250px;
              border: 1px dashed #bfc0c2 !important;
              display: flex;
              justify-content: center;
              align-items: center;
              .upload-guides-video-form-video-right-box {
                .url-of-video-input {
                  background-color: #f7f7f7;
                  height: 40px;
                  margin-top: 15px;
                  width: 344px;

                  input::placeholder {
                    color: #405261 !important;
                    padding-left: -5px;
                    font-family: 'Ubuntu', serif;
                    font-weight: 300;
                    font-style: normal;
                  }
                  input:-webkit-autofill {
                    background-color: transparent !important;
                    -webkit-box-shadow: 0 0 0 40px #f7f7f7 inset !important; // Matches input height
                    box-shadow: 0 0 0 40px #f7f7f7 inset !important;
                    -webkit-text-fill-color: #405261 !important;
                    font-family: 'Ubuntu', serif;
                    font-weight: 300;
                    border-radius: 4px;
                    height: 2px;
                  }
                  .url-of-video-close {
                    cursor: pointer;
                  }
                }
                .upload-guides-video-form-video-right-box-img {
                  text-align: center;
                }
                .upload-guides-video-form-video-right-box-text-link {
                  font-family: Roboto;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 25.6px;
                  letter-spacing: -0.01em;
                  text-align: center;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;
                  color: #8f8f8f;
                  margin-top: 12px;
                  span {
                    color: #cac6c6;
                    border-bottom: 1px solid #cac6c6;
                    cursor: pointer;
                  }
                }
                .upload-guides-video-form-video-right-box-add-button {
                  display: flex;
                  justify-content: center;
                  margin-top: 12px;
                  font-family: Roboto;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 25.6px;
                  letter-spacing: -0.01em;
                  text-align: left;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;
                  .btn {
                    width: 148px;
                    height: 42px;
                    background-color: #887952;
                    border-radius: 6px;
                    border: none;
                    color: white;
                    font-size: medium;
                    cursor: pointer;
                    font-weight: bold;
                  }
                }
              }
            }
            .upload-guides-video-form-video-left {
              width: 39%;
              height: 250px;
              border: 1px dashed #bfc0c2 !important;
              display: flex;
              justify-content: center;
              align-items: center;
              .upload-guides-video-form-video-left-box {
                .upload-guides-video-form-video-left-box-add-button {
                  display: flex;
                  justify-content: center;
                  margin-top: 12px;
                  font-family: Roboto;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 25.6px;
                  letter-spacing: -0.01em;
                  text-align: left;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;
                  .btn {
                    width: 200px;
                    height: 42px;
                    background-color: #887952;
                    border-radius: 6px;
                    border: none;
                    color: white;
                    font-size: medium;
                    cursor: pointer;
                    font-weight: bold;
                  }
                }
                .url-of-video-input {
                  background-color: #f7f7f7;
                  height: 40px;
                  margin-top: 15px;
                  width: 250px;

                  input::placeholder {
                    color: #405261 !important;
                    padding-left: -5px;
                    font-family: 'Ubuntu', serif;
                    font-weight: 300;
                    font-style: normal;
                  }
                  input:-webkit-autofill {
                    background-color: transparent !important;
                    -webkit-box-shadow: 0 0 0 40px #f7f7f7 inset !important; // Matches input height
                    box-shadow: 0 0 0 40px #f7f7f7 inset !important;
                    -webkit-text-fill-color: #405261 !important;
                    font-family: 'Ubuntu', serif;
                    font-weight: 300;
                    border-radius: 4px;
                    height: 2px;
                  }
                  .url-of-video-close {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
    .upload-guides-video-bottom {
      display: flex;
      flex-direction: row-reverse;
      padding: 20px 0px;
      margin-top: 20px;
      border-top: 1px solid #d8d8d8;
      .cancel-button {
        background: white;
        color: black;
        padding: 8px 15px;
        border: 1px solid rgb(215, 215, 215);
        border-radius: 6px;
        cursor: pointer;
        font-weight: 600;
        font-size: medium;
        margin-left: 20px;
      }
      .save-button {
        width: 128px;
        height: 42px;
        background-color: #887952;
        border: none;
        color: white;
        font-weight: 500;
        border-radius: 8px;
        border: #887952;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
