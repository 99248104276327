.no-role{
    background-color: #FFF9EA;
    height: 100vh; 
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center!important;
    .no-role-container{
        position: relative;
        background-color: #fff!important;
        height: 375px!important;
        width: 419px!important;
        padding: 40px!important;
        top: 0!important;
        bottom: 0!important;
        left: 0!important;
        right: 0!important;
        .reset-password-heading{
            text-align: center!important;
        }
        .reset-password-heading-icon{
            text-align: center!important;
        }
        .no-role-container-text{
            text-align: center!important;
            h3{
                font-family: Roboto;
                font-size: 23px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
            }
            p{
                font-family: Roboto;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;

            }
            .fa-exclamation-triangle{
                color: red;
            }
        }
        .no-role-container-button{
            border-radius: 8px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 25.6px;
            letter-spacing: -0.01em;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            padding: 8px 24px;
            color: #fff;
            background-color: #887952;
            border: 1px solid #887952;
        }
    }
}