@import '../../assets/styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
.container {
  display: flex;
  height: 100vh; 
}
.middleSection {
  margin-top: 150px;
}
.leftSection, .rightSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;

}

.leftSection {
  background-color: $background-color;
  flex-direction: column;
  text-align: center;
  .leftSectionContent{
    width: 339px;
    height: 305.58px;
    gap:22px;

  }

  h1 {
    font-size: 24px;
    margin-top: 20px;
    line-height: 32px;
    align-items: center;
    font-family: "Ubuntu", serif;
    font-weight: 500;
    font-style: normal;
    color: $text-color;
  }

  p {
    font-size: 16px;
    line-height: 25.6px;
    color: $paragraph_color;
    font-family: "Ubuntu", serif;
    font-weight: 400;
    font-style: normal;
  }
}

.rightSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff; 
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 60px;
}

.loginForm {
  width: 340px;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:22px;
  margin: auto;
  h2 {
    width: 340px;
    height: 32px;
    margin-bottom: 1rem;
    font-size: 24px;
    color: $text-color;
    line-height: 32px;
    font-family: "Ubuntu", serif;
    font-weight: 500;
    font-style: normal;
  }
  .formBody{
    margin-top: -35px;
    width: 340px;
    height: 194px;
  }
  .field {
    position: relative;
    input {
      width: 330px;
      height: 40px;
      opacity: 80%;
      font-size: 16px;
      background-color: $forminputbackground_color;
      line-height: 25.6px;
      border: none;
      border-radius: 4px;
      margin-top: 15px;
      padding-left: 10px;
    }
    input::placeholder {
      color: #a0a3a5;
      font-family: "Ubuntu", serif;
      font-weight: 300;
      font-style: normal;
    }
    .errorMessage{
      color: red!important;
    }
  }

  .errorMessage{
    color: red!important;
  }

  .errorMessageMsg{
    color: red!important;
    margin-top: 10px;
  }

  .submitButton {
    background-color: $buttonbackground_color;
    color: #fff;
    width: 345px;
    height: 48px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    line-height: 25.6px;
    font-family: "Ubuntu", serif;
    font-weight: 300;
    font-style: normal;
    margin-top: 10px;

  }
  .passwordToggleIcon {
    position: absolute;
    margin-top: -31px;
    right: 1rem;
    cursor: pointer;
    font-size: 1.2rem;
    color: #888;
  
    &:hover {
      color: #333;
    }
  }

  .passwordToggleIconInputAdornment{
    cursor: pointer;
    font-size: 1.2rem;
    color: #888;
  
    &:hover {
      color: #333;
    }
  }

  .passwordFieldOutlinedInput{
    border: none;
  }
  .helperLinks {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    color: $text-color;
    margin-top: 20px;

    label {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 25.6px;
      font-family: "Ubuntu", serif;
      font-weight: 400;
      font-style: normal;
      width: 155px;
      height: 26px;
    }

    a {
      width: 130px;
      height: 26px;
      font-size: 16px;
      margin-top: 2px;
      line-height: 25.6px;
      color: #005881;
      font-family: "Ubuntu", serif;
      font-weight: 400;
      font-style: normal;
      text-decoration: none;
    }
  }
  .helperLinks input[type="checkbox"] {
    margin-right: 8px; /* Adjust the space as needed */
  }
  
  a {
    width: 112px;
    height: 30px;
    color: #005881;
    font-size: 16px;
    line-height: 60.6px;
    margin: 10px;
    margin-left: 0px;
    text-decoration: none;
    align-self: flex-start;
    font-family: "Ubuntu", serif;
    font-weight: 400;
    font-style: normal;
  }
  .termscheckbox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    color: $text-color;
    margin-top: 20px;

    label {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 25.6px;
      font-family: "Ubuntu", serif;
      font-weight: 400;
      font-style: normal;
      width: 340px;
      height: 52px;
    }

    a {
      width: 130px;
      height: 26px;
      font-size: 16px;
      margin-top: 2px;
      line-height: 25.6px;
      color: #005881;
      font-family: "Ubuntu", serif;
      font-weight: 400;
      font-style: normal;
      text-decoration: none;
      
    }
  }
  .termscheckbox input[type="checkbox"] {
    margin-right: 10px; 
    margin-bottom: 30px;
  }
  
}