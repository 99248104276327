.add-new-funeral-homes {
  .add-new-funeral-homes-container {
    border-top: 1px solid #dfdddd;
    .view-funeral-homes-bottom-container-tab-name {
      border: 1px solid #dfdddd;
      border-top: none !important;
      width: 100%;
      background-color: #fff;
      z-index: 20;
      position: absolute !important;
      .MuiButtonBase-root {
        border: 1px solid #dfdddd;
        border-top: none !important;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #4b4f50;
        text-transform: none !important;
        position: sticky !important;
        display: inline-flexbox !important;
      }
      .MuiTabs-indicator {
        background-color: #887952 !important;
      }
      .Mui-selected {
        font-family: Roboto;
        color: #fff !important;
        border: 1px solid #dfdddd !important;
        text-decoration: none !important;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        background-color: #887952;
        border-radius: 6px;
      }
    }
    .MuiBox-root {
      overflow: auto;
    }
    .MuiBox-root {
      height: 68vh;
      overflow-y: auto;
      margin-top: 50px;
    }
  }
  .funeral-homes-form-button-container {
    bottom: 20px;
    display: flex;
    justify-content: flex-end;
    padding: 20px 18px;
    button {
      height: 40px;
      font-size: 16px;
      text-align: center;
      font-family: Roboto, Arial, sans-serif;
      border-radius: 4px;
      cursor: pointer;
    }
    .cancel-edit-details {
      background-color: white;
      border: 1px solid black;
      width: 73px;
    }
    .save-edit-details {
      margin-left: 9px;
      background-color: #887952;
      border: none !important;
      width: 130px;
      color: #f7f7f7;
    }
  }
}
