/* Full-screen overlay */
.logout-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1080;

  .logout-popup {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    height: 120px;
    width: 350px;
    z-index: 1001;
    
    .logout-message {
      font-size: 0.85rem;
      color: #555;
      margin-bottom: 20px;
      text-align: center;
    }

    .logout-buttons {
      display: flex;
      justify-content: space-around;
      margin-top: 15px;
      gap: 2%;

      .cancel-button {
        background: white;
        color: black;
        padding: 8px 15px;
        border: 1px solid rgb(215, 215, 215);
        border-radius: 6px;
        cursor: pointer;
        font-weight: 600;
        font-size: medium;
      }

      .logout-button {
        background: #dc3545;
        color: white;
        padding: 8px 15px;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-weight: 600;
        font-size: medium;

        &:hover {
          background: #c82333;
        }
      }

      .cancel-button,
      .logout-button {
        width: 48%;
        height: 2.5rem;
      }
    }
  }
}
