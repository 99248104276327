.saved-reports-row {
    margin: 30px !important;

    .saved-reports-table-container {
        height: fit-content;
        overflow-y: auto;
        overflow-x: auto;
        width: 100%;
        margin-right: 3px;
        border-radius: 6px !important;
        border: 1px solid #DDDADA;

        .saved-reports-table-container {
            border-collapse: collapse;
            width: 100%;
            font-size: 14px !important;
            .saved-report-download-button {
                background-color: white;
                border: 1.5px solid #887952 ;
                width: 120px;
                border-radius: 6px;
                padding: 3px;
                height: 32px;
                color: #887952;
                cursor: pointer;
            }
            .saved-report-download-button-disabled {
                background-color: white;
                border: 1.5px solid #887952 ;
                width: 120px;
                border-radius: 6px;
                padding: 3px;
                height: 32px;
                color: #887952;
                cursor: not-allowed;
            }
        }

        .header-column-names-arrow {
            display: flex;
            justify-content: space-between;
            font-size: 14px !important;
            font-weight: 500;
            .header-first-column{
                margin-left: 10px;
            }
        }
    }
}

.saved-reports-table {
    tr th{
        background-color:#EEEE!important;
        max-width: 55px;
        min-width: 55px;
        height: 30px;
    }
    tr td{
        background-color: #fff!important;
        max-width: 45px;
        min-width: 45px;
    }
    tr td, th{
        padding: 8px;
    }
}