.view-funeral-homes-bottom-container {
  .view-funeral-homes-bottom-container-tab-name {
    border: 1px solid #dfdddd;
    .MuiButtonBase-root {
      border: 1px solid #dfdddd;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #4b4f50;
      text-transform: none !important;
    }
    .MuiTabs-indicator {
      background-color: #887952 !important;
    }
    .Mui-selected {
      font-family: Roboto;
      color: #fff !important;
      text-decoration: none !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      background-color: #887952;
      border-radius: 6px;
    }
  }

  .MuiBox-root {
    overflow: auto;
  }
}
