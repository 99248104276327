/* Full-screen overlay */
.delete-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1080;

  .delete-popup {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    z-index: 1001;
    width: 25%;
    height: auto;
    .delete-popup-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      background: rgba(227, 214, 214, 0.5);
      position: relative;
      box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.1);
      transform: translateZ(50px);
      perspective: 1000px;
      .trashIcon-background {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 39px;
        height: 39px;
        border-radius: 50%;
        background: rgba(255, 130, 130, 0.2);
        position: relative;
        img {
          width: 24px;
          height: 24px;
          display: block;
        }
      }
    }

    .delete-title {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 10px;
      text-align: left;
    }

    .delete-message {
      font-size: 0.85rem;
      color: #555;
      margin-bottom: 20px;
      text-align: left;
    }

    .delete-buttons {
      display: flex;
      justify-content: space-around;
      margin-top: 15px;
      gap: 2%;

      .cancel-button {
        background: white;
        color: black;
        padding: 8px 15px;
        border: 1px solid rgb(215, 215, 215);
        border-radius: 6px;
        cursor: pointer;
        font-weight: 600;
        font-size: medium;
      }

      .delete-button {
        background: #dc3545;
        color: white;
        padding: 8px 15px;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-weight: 600;
        font-size: medium;

        &:hover {
          background: #c82333;
        }
      }

      .cancel-button,
      .delete-button {
        width: 48%;
        height: 2.5rem;
      }
    }
  }
}

//   .delete-icon {
//     font-size: 2rem;
//     color: #dc3545; /* Red color */
//     margin-bottom: 10px;
//   }
