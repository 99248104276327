@import '../../assets/styles/variables.scss';
.offcanvas {
    position: fixed !important;
    right: 0;
    top: 0;
    width: 40%; 
    z-index: 6000;
    background-color: white;
    border: 2px solid white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    .offcanvas-header {
        margin-bottom: 13px;
        box-sizing: border-box;
        height: 69px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.181);
        align-items: center;
        .offcanvas-title {
          margin-top: 8px;
        }
        .header-div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0px;
            width: 100%;
            h2 {
                margin: 0; 
                padding: 0 35px !important; 
                font-size: 1.5rem; 
                width: 90%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre;
            }
            button {
                background: none;
                border: none;
                padding: 0;
                cursor: pointer;
                width: 10%;
                .header-div-img{
                  cursor: pointer
                }
              }
        }
    }
    .offcanvas-body {
        flex: 1;
        overflow-y: auto;
        padding-top: 0px !important;
        .add-or-edit-form, .filters-form {  
          padding: 0px 18px;  
          .form-button-container, .filter-form-buttons {
            margin-top: 40px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            .cancel-button, .cancel-button-in-edit, .filter-reset-button {
              width: 81px;
              outline: none;
              background-color: white;
            }
            .cancel-button {
              margin-left: 349px;
            }
            .cancel-button-in-edit {
              margin-left: 251px;
            }
            .save-button, .filter-apply-button {
              width: 127px;
              height: 42px;
              margin-left: 10px;
              background-color: $army-brown;
              border: none;
              color: white;
              font-weight: bold;
              border-radius: 8px;
              border: $army-brown;
              font-size: 16px;
              cursor: pointer;
            }
            .save-button-changes{
              width: 148px;
              height: 42px;
              margin-left: 10px;
              background-color: $army-brown;
              border: none;
              color: white;
              font-weight: 500;
              border-radius: 8px;
              border: $army-brown;
              font-size: 16px;
              cursor: pointer;
            }
            .delete-button {
              width: 78px;
              height: 40px;
              background-color: white;
              border: 1px solid #EB4F46;
              color: #EB4F46;
              border-radius: 4px;
              font-size: 16px;
              font-weight: 400;
              cursor: pointer;
            }
            .cancel-button,.cancel-button-in-edit, .filter-reset-button {
              align-content: center;
              padding: 4px;
              height: 40px;
              border-radius: 4px;
              border: 1px solid #182D40;
              font-size: medium;
              cursor: pointer;
            }
          }
          .add-or-edit-form-group{
            .checkbox-group{
              display: flex;
              align-items: center;
              gap: 16px;
              font-weight: 600;
              font-size: 15px;
              padding-top: 15px;
            }
          }
        }
    }
}
.offcanvas.show {
  transform: translateX(0) !important;
}

.add-or-edit-offcanvas,
.filter-offcanvas {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.offcanva-box {
  width: 340px !important;
}

.filter-offcanvas-header {
  .offcanvas-title {
    margin-right: 8px;
  }
}

.filter-form-buttons {
  justify-content: space-between;
}

.filters {
  .flatpickr-input {
    width: 100%;
  }
}

.flatpickr-calendar {
    right:20px !important;
  }

.flatpickr-day.startRange,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange,
.flatpickr-day.endRange:hover,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay {
  background-color: #887952;
  border-color: #887952;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #e2e2e2;
}