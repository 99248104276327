.main-layout {
    display: flex;
    width:100%;
    height: 100vh;
    align-items: stretch;
    .content {
      flex:1;
      width: calc(100% - 275px);
      overflow: auto;
    }

    .content::-webkit-scrollbar {
      display: none;
    }

    .content {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }