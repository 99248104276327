@import '../../../../assets/styles/variables.scss';
.add-or-edit-agencies-offcanvas{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}
.add-or-edit-agencies-popup{
    .offcanvas-body{
        overflow: hidden;
        flex: 1;
        padding: 0px!important;
        .add-or-edit-agencies{
            width: 93%;
            
            .agencies-agencie-detail{
                padding: 20px 20px;
                width: 100%;
                .agencies-agencie-detail-heading{
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;     
                    color: #212121;       
                }
                .agencies-agencie-detail-container{
                    margin-top: 16px;
                    border: 1px solid #E1E1E1;
                    border-radius: 6px;
                    .agencies-agencie-detail-form{
                        padding: 6px 12px;
                        padding-bottom: 16px;
                        .heading-name-label{
                            .custom-form-label{
                                font-family: Roboto;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                color: #212121;
                                margin-bottom: 16px;
                            }
                            .form-control{
                                height: 40px;
                                border-radius: 4px;
                            }
                        }
                        .sub-name-label{
                            .custom-form-label{
                                font-family: Roboto;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                margin-bottom: 12px;
                                color: #212121;                                
                            }
                            .form-control {
                                height: 40px;
                                border-radius: 4px;
                            }
                            .custom-country-code-dropdown{
                                height: 56px;
                                border-radius: 4px;
                            }
                            .custom-input-form-control {
                                height: 3.4rem !important;
                            }
                        }
                        .single-line-row-form-input{
                            width: 100%;
                            .add-or-edit-form-group{
                                border: none!important;
                                margin-top: 16px;
                                .custom-form-label{

                                }
                                .custom-form-div{
                                    width: 100%;
                                    border: none!important;
                                    border-radius: 0px;
                                    margin-bottom: 0px;
                                    border-top: 1px solid #DFDDDD!important;
                                    padding: 12px 0px!important;
                                    margin-bottom: 0px;
                                    .MuiAutocomplete-root{
                                        height: 56px;
                                        width: 17.5rem;
                                    }
                                    .MuiAutocomplete-root .MuiInputBase-input::placeholder {
                                        color: #757575;
                                        opacity: 1;
                                    }
                                    .custom-form-group-row{
                                        justify-content: space-between;
                                        width: 100%;
                                        .custom-form-group-col{
                                            margin-left: 0px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .agencies-owner-details{
                padding: 0px 20px;
                width: 100%;
                .agencies-owner-details-heading{
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;     
                    color: #212121;       
                }
                .agencies-owner-details-container{
                    margin-top: 16px;
                    border: 1px solid #E1E1E1;
                    border-radius: 6px;
                    .agencies-owner-details-sub-container{
                        padding: 16px 12px;
                        padding-top: 0px;
                        .agencies-owner-details-add-btn-container{
                            display: flex;
                            flex-direction: row-reverse;
                            margin-top: 16px;
                            .agencies-owner-details-add-btn{
                                cursor: pointer;
                                font-family: Roboto;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                color: #785607;
                            }
                        }
                        .agencies-owner-details-container-form{
                            padding: 6px 12px;
                            margin-top: 20px;
                            padding-right: 24px;
                            border: 1px solid #DFDDDD;
                            border-radius: 6px;
                            padding-bottom: 16px;
                            .agencies-owner-details-remove-btn-container{
                                display: flex;
                                flex-direction: row-reverse;
                                margin-top: 16px;
                                .agencies-owner-details-remove-btn {
                                    cursor: pointer;
                                    font-family: Roboto;
                                    font-size: 24px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    text-align: left;
                                    text-underline-position: from-font;
                                    text-decoration-skip-ink: none;
                                    color: #212121;
                                }
                                .agencies-owner-details-remove-btn:hover {
                                    color: #785607;
                                }
                            }
                            .heading-name-label{
                                .custom-form-label{
                                    font-family: Roboto;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    text-align: left;
                                    text-underline-position: from-font;
                                    text-decoration-skip-ink: none;
                                    color: #212121;
                                    margin-bottom: 16px;
                                }
                                .form-control{
                                    height: 40px;
                                    border-radius: 4px;
                                }
                                .flatpickr-input{
                                    height: 40px!important;
                                    border-radius: 4px;
                                    width: 100%;
                                    border: none!important;
                                    background-color: #F7F7F7;
                                }
                            }
                            .sub-name-label{
                                .custom-form-label{
                                    font-family: Roboto;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 20px;
                                    text-align: left;
                                    text-underline-position: from-font;
                                    text-decoration-skip-ink: none; 
                                    color: #212121;                               
                                    margin-bottom: 4px;
                                }
                                .form-control, .custom-country-code-dropdown{
                                    height: 40px;
                                    border-radius: 4px;
                                }
                            }
                            .multi-line-row-form-input {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                margin-top: 6px;
                                .multi-line-row-form-input-single{
                                    width: 48%;
                                    .custom-form-group-col-select{
                                        width: 40%;
                                        height: 56px;
                                        .custom-country-code-dropdown{
                                            height: 56px;
                                            .custom-country-code-dropdown__control{
                                                height: 56px;
                                            }
                                        }
                                    }
                                    .css-g5z29e-control {
                                        height: 57px;
                                        border: 0px !important;
                                    }
                                }
                            }

                            .multi-line-row-form-checkbox{
                                display: flex;
                                width: 38%;
                                justify-content: space-between;
                                margin-top: 6px;
                            }
                            .single-line-row-form-input{
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .agencies-other-details{
                padding: 0px 20px;
                width: 100%;
                .agencies-other-details-heading{
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;     
                    color: #212121;       
                }
                .agencies-other-details-container{
                    margin-top: 16px;
                    border: 1px solid #E1E1E1;
                    border-radius: 6px;
                    .agencies-other-details-sub-container{
                        padding: 16px 12px;
                        padding-top: 0px;
                        .agencies-other-details-add-btn-container{
                            display: flex;
                            flex-direction: row-reverse;
                            margin-top: 16px;
                            .agencies-other-details-add-btn{
                                cursor: pointer;
                                font-family: Roboto;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                color: #785607;
                            }
                        }
                        .agencies-other-details-container-form{
                            padding: 6px 12px;
                            margin-top: 20px;
                            padding-right: 24px;
                            border: 1px solid #DFDDDD;
                            border-radius: 6px;
                            padding-bottom: 16px;
                            .agencies-other-details-remove-btn-container{
                                display: flex;
                                flex-direction: row-reverse;
                                margin-top: 16px;
                                .agencies-other-details-remove-btn {
                                    cursor: pointer;
                                    font-family: Roboto;
                                    font-size: 24px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    text-align: left;
                                    text-underline-position: from-font;
                                    text-decoration-skip-ink: none;
                                    color: #212121;
                                }
                                .agencies-other-details-remove-btn:hover {
                                    color: #785607;
                                }
                            }
                            .heading-name-label{
                                .custom-form-label{
                                    font-family: Roboto;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    text-align: left;
                                    text-underline-position: from-font;
                                    text-decoration-skip-ink: none;
                                    color: #212121;
                                    margin-bottom: 16px;
                                }
                                .form-control{
                                    height: 40px;
                                    border-radius: 4px;
                                }
                                .flatpickr-input{
                                    height: 40px!important;
                                    border-radius: 4px;
                                    width: 100%;
                                    border: none!important;
                                    background-color: #F7F7F7;
                                }
                            }
                            .agencies-other-details-single-input-label-box{
                                width: 100%;
                                .custom-form-label{
                                    font-family: Roboto;
                                    font-size: 16px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    text-align: left;
                                    text-underline-position: from-font;
                                    text-decoration-skip-ink: none;                                                 
                                    color: #212121;
                                    margin-bottom: 16px;
                                    margin-top: 20px;
                                }

                                .flatpickr-input{
                                    height: 40px!important;
                                    border-radius: 4px;
                                    width: 100%;
                                    border: none!important;
                                    background-color: #F7F7F7;
                                    text-align: left;
                                    padding-left: 8px;
                                }
                                
                                .form-control{
                                    height: 40px;
                                    border-radius: 4px;
                                }
                            }
                        }
                    }
                }
            }

            .agencies-other {
                padding: 20px 20px;
                width: 100%;
                .agencies-other-heading{
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;     
                    color: #212121;       
                }
                .agencies-other-container{
                    margin-top: 16px;
                    border: 1px solid #E1E1E1;
                    border-radius: 6px;
                    .agencies-other-container-form{
                        padding: 0px 12px;
                        padding-bottom: 16px;
                        .single-line-row-form-input{
                            width: 100%;
                            textarea {
                                font-family: Roboto;
                                font-size: medium;
                                font-weight: 400;
                                line-height: 25.6px;
                                letter-spacing: -0.01em;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                            }
                            .custom-form-label{
                                font-family: Roboto;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;                                                                                
                                color: #212121;
                                margin-bottom: 10px;
                                margin-top: 16px;
                            }
                        }
                    }
                }
            }

            .agencies-single-input-label-box{
                padding: 0px 20px;
                margin-top: 6px;
                width: 100%;
                .custom-form-label{
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;                                                 
                    color: #212121;
                    margin-bottom: 16px;
                    margin-top: 20px;
                }
                textarea {
                    font-family: Roboto;
                    font-size: medium;
                    font-weight: 400;
                    line-height: 25.6px;
                    letter-spacing: -0.01em;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                }
                .form-control{
                    height: 40px;
                    border-radius: 4px;
                }
            }

            .agencies-multi-input-label-box{
                    padding: 0px 20px;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 6px;
                    .multi-line-row-form-input-single{
                        width: 48%;
                        .custom-form-label{
                            font-family: Roboto;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 20px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;                            
                            color: #212121;
                            margin-bottom: 16px;
                        }
                        .form-control{
                            height: 40px;
                            border-radius: 4px;
                        }
                    }
                
            }
            .MuiAutocomplete-root{
                background-color: #F7F7F7;
            }
            .MuiAutocomplete-root .MuiInputBase-input::placeholder {
                color: #757575;
                opacity: 1;
            }
        }
        .form-button-container {
            width: 100%;
            padding: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;            
            .cancel-button, .cancel-button-in-edit {
              width: 81px;
              outline: none;
              background-color: white;
            }
            .save-button {
                width: 127px;
                height: 42px;
                margin-left: 10px;
                background-color: $army-brown;
                border: none;
                color: white;
                font-weight: bold;
                border-radius: 8px;
                border: $army-brown;
                font-size: 16px;
                cursor: pointer;
            }
            .save-button-changes{
                width: 148px;
                height: 42px;
                margin-left: 10px;
                background-color: $army-brown;
                border: none;
                color: white;
                font-weight: 500;
                border-radius: 8px;
                border: $army-brown;
                font-size: 16px;
                cursor: pointer;
              }
            .delete-button {
                width: 78px;
              height: 40px;
              background-color: white;
              border: 1px solid #EB4F46;
              color: #EB4F46;
              border-radius: 4px;
              font-size: 16px;
              font-weight: 400;
              cursor: pointer;
            }
            .cancel-button,  .cancel-button-in-edit {
                align-content: center;
                padding: 4px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #182D40;
                font-size: medium;
                cursor: pointer;
            }
          }
    }
}
