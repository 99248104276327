.menu {
  background-color: rgba(0, 0, 0, 0.4);
}

.image-wrapper {
  padding: 10px;
  width: 200px;
}

.displayed-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}
