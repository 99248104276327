.view-details-section {
  width: 95%;
  border: 1px solid #dfdddd;
  margin: 20px;
  border-radius: 16px;
  .view-details-header-section {
    width: 100%;
    padding: 20px;
    .view-details-header-tab {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 96%;
      .view-details-header-text {
        font-family: Roboto;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
        margin-bottom: 12px;
      }
      .edit-icon-section {
        .edit-icon-styles {
          cursor: pointer;
          border-radius: 5px;
          &:hover {
            background-color: darken(#785607, 2%);
            transition: background-color 0.2s ease;
          }
        }
      }
    }
    .view-details-data-section {
      width: 96%;
      .view-details-data-row {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        div {
          font-family: Roboto, Arial, sans-serif;
        }
        .view-details-key {
          text-align: left;
          width: 150px;
        }
        .view-details-value {
          text-align: left;
          width: calc(100% - 150px);
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
