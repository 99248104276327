.dashboard {
  width: 100%;
  height: 88vh;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  .dashboard-img {
    width: 100%;
    height: 100%;
  }
  .dashboard-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    .dashboard-header-text-day-filter {
      margin-top: 20px;
      height: 20px;
      padding: 0px 40.5px;
      display: flex;
      justify-content: space-between;
      .dashboard-header-text {
        padding: 4px 8px !important;
        font-family: Ubuntu;
        font-size: 14px;
        font-weight: 700;
      }
      .dashboard-header-day-filter-text-icons {
        display: flex;
        width: 72px !important;
        align-items: center;
        padding: 4px 8px;
        .dashboard-header-day-filter-text {
          font-family: Ubuntu;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          color: #1c1c1c;
        }
        .dashboard-header-day-filter-icons {
          margin-left: 4px !important;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .dashboard-chart-container-one {
      display: flex;
      margin-top: 16px;
      padding: 0px 40.5px;
      justify-content: space-between;
      .dashboard-chart-container-one-icon-text {
        text-decoration: none;
        width: 23%;
        height: 94px;
        border: 1px solid #cecece;
        border-radius: 16px;
        cursor: pointer;
        &:hover {
          outline: 3px solid #887952;
        }
        a {
          text-decoration: none;
          color: inherit;
        }
        .dashboard-chart-container-one-icon-text-inner {
          padding: 24px;
          .dashboard-chart-container-one-icon-text-header {
            font-family: Ubuntu;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }
          .dashboard-chart-container-one-icon-sub-text {
            display: flex !important;
            margin-top: 8px;
            width: 100%;
            justify-content: space-between;
            .dashboard-chart-container-one-icon-sub-text-left {
              font-family: Ubuntu;
              font-size: 24px;
              font-weight: 500;
              line-height: 18px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
            .dashboard-chart-container-one-icon-sub-text-right {
              display: flex;
              align-content: baseline;
              justify-content: space-between;
              .dashboard-chart-container-one-icon-sub-text-right-icon {
                height: 16px;
                width: 16px;
              }
              .dashboard-chart-container-one-icon-sub-text-right-text {
                font-family: Ubuntu;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
              }
            }
          }
        }
      }
    }
    .dashboard-chart-container-two {
      display: flex;
      // margin-top: 28px;
      margin-top: 16px;
      padding: 0px 40.5px;
      justify-content: space-between;
      background-color: #ffff;
      border-radius: 20px;
      .dashboard-chart-container-two-left {
        width: 45%;
        background-color: #f7f9fb;
        padding: 20px;
        padding-bottom: 5px !important;
        padding-top: 10px;
        border-radius: 20px;
        .dashboard-chart-container-two-left-heading {
          font-family: Roboto;
          font-size: 20px;
          font-weight: 700;
          line-height: 32px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
        .dashboard-chart-container-two-left-chart {
          width: 100%;
          // margin-top: 45px;
          // padding-bottom: 21px;
          // padding-left: 42px;
          .MuiChartsLegend-series {
            .MuiChartsLegend-mark {
              height: 10px;
              width: 10px;
            }
            tspan {
              font-family: Roboto;
              font-size: 13.66px;
              font-weight: 400;
              line-height: 20.49px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
          }
        }
      }
      .dashboard-chart-container-two-right {
        width: 45%;
        background-color: #f7f9fb;
        padding: 20px;
        padding-bottom: 5px !important;
        padding-top: 10px;
        border-radius: 20px;
        .dashboard-chart-container-two-right-heading {
          font-family: Roboto;
          font-size: 20px;
          font-weight: 700;
          line-height: 32px;
          text-align: left;
          text-underline-position: from-font;
        }
        .dashboard-chart-container-two-right-chart {
          width: 100%;
          // margin-top: 45px;
          // padding-bottom: 21px;
          // padding-left: 42px;
          .MuiChartsAxis-line {
            display: none !important;
          }
          .MuiChartsAxis-tick {
            display: none !important;
          }
          .MuiChartsAxis-tickContainer {
            .MuiChartsAxis-tickLabel {
              font-size: 12px !important;
            }
          }
          .MuiChartsLegend-series {
            .MuiChartsLegend-mark {
              height: 15px;
              width: 15px;
            }
            tspan {
              font-family: Roboto;
              font-size: 13.66px;
              font-weight: 400;
              line-height: 20.49px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
          }
        }
      }
    }
    .dashboard-chart-container-three {
      display: flex;
      margin-top: 28px;
      padding: 0px 40.5px;
      justify-content: space-between;
      background-color: #ffff;
      border-radius: 20px;
      .dashboard-chart-container-three-left {
        width: 45%;
        background-color: #f7f9fb;
        padding: 20px;
        padding-bottom: 5px !important;
        border-radius: 20px;
        .dashboard-chart-container-three-left-heading {
          font-family: Roboto;
          font-size: 20px;
          font-weight: 700;
          line-height: 32px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
        .coming-soon-tag {
          font-family: Roboto;
          font-size: 16px;
          color: #785607;
          padding: 3px 8px;
          border-radius: 15px;
          display: flex;
          flex-direction: row-reverse;
        }
        .dashboard-chart-container-three-left-chart {
          width: 100%;
          // margin-top: 45px;
          // padding-bottom: 21px;
          // padding-left: 42px;
          .MuiChartsLegend-series {
            .MuiChartsLegend-mark {
              height: 10px;
              width: 10px;
            }
            tspan {
              font-family: Roboto;
              font-size: 13.66px;
              font-weight: 400;
              line-height: 20.49px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
          }
        }
      }
      .dashboard-chart-container-three-right {
        width: 45%;
        background-color: #f7f9fb;
        padding: 20px;
        padding-bottom: 5px !important;
        border-radius: 20px;
        .dashboard-chart-container-three-right-heading {
          font-family: Roboto;
          font-size: 20px;
          font-weight: 700;
          line-height: 32px;
          text-align: left;
          text-underline-position: from-font;
        }
        .dashboard-chart-container-three-right-chart {
          width: 100%;
          // margin-top: 45px;
          // padding-bottom: 21px;
          // padding-left: 42px;
          .MuiChartsLegend-series {
            .MuiChartsLegend-mark {
              height: 10px;
              width: 10px;
            }
            tspan {
              font-family: Roboto;
              font-size: 13.66px;
              font-weight: 400;
              line-height: 20.49px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
          }
        }
      }
    }
  }
}

// .dashboard::-webkit-scrollbar {
//     display: none;
// }

.dashboard-container::-webkit-scrollbar {
  display: none;
}

@media screen and (max-height: 736px) {
  .dashboard {
    height: 90vh;
    overflow: auto !important;
    .dashboard-container {
      overflow: auto !important;
      height: 100vh;
    }
  }
}
